import { useState } from "react";
import { useQueryClient } from "react-query";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import { ADMIN_BID_QUERY_KEY_GEN } from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import PARTNER_QUERY from "@sellernote/shared/src/queries/forwarding/PARTNER_QUERY";
import { BidStatus } from "@sellernote/shared/src/types/forwarding/bid";
import { PartnerListItem } from "@sellernote/shared/src/types/forwarding/partner";

import PartnerSelectConfirmModal from "./PartnerSelectConfirmModal";

export default function QuotePartnerRadioList({
  partnerList,
  shipmentId,
  onPartnerQuoteListRefetch,
  bidStatus,
}: {
  partnerList: PartnerListItem[];
  shipmentId: number;
  onPartnerQuoteListRefetch: () => void;
  bidStatus: BidStatus;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  /** MUI uncontrolled value 에러로 기본값은 -1처리 */
  const [quoteLogId, setQuoteLogId] = useState(-1);
  const [opensConfirmModal, setOpensConfirmModal] = useState(false);

  const queryClient = useQueryClient();

  const {
    mutate: selectQuotationPartner,
    ResponseHandler: ResponseHandlerOfSelectQuotationPartner,
  } = PARTNER_QUERY.useSelectQuotationPartner({
    shipmentId,
    quoteLogId,
  });

  const handleSelectConfirm = () => {
    selectQuotationPartner(
      {},
      {
        onSuccess: () => {
          setOpensConfirmModal(false);
          onPartnerQuoteListRefetch();
          handleSnackbarOpen("요청에 성공했습니다.");

          queryClient.invalidateQueries(
            ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
              bidId: shipmentId,
            })
          );
        },
      }
    );
  };

  const handleModalOpenAndSetLogId = (id: number) => {
    setOpensConfirmModal(true);
    setQuoteLogId(id);
  };

  /** 의뢰 받음, 견적 산출 중 상태일 때만 변경 가능 */
  const allowsControl = bidStatus === "committed" || bidStatus === "estimating";

  return (
    <>
      <FormControl>
        <RadioGroup
          sx={{ flexDirection: "row" }}
          aria-label="quote-partner-selection"
          value={quoteLogId}
          onChange={(e) => {
            handleModalOpenAndSetLogId(Number(e.target.value));
          }}
        >
          {partnerList.map((partner) => {
            return (
              <FormControlLabel
                sx={{ margin: 0 }}
                key={partner.id}
                value={partner.requestQuoteLog[0].id}
                control={<Radio />}
                label={partner.name}
                checked={Boolean(partner.requestQuoteLog[0].checkedAt)}
                disabled={!allowsControl}
              />
            );
          })}
        </RadioGroup>
      </FormControl>

      <PartnerSelectConfirmModal
        onSelectConfirm={handleSelectConfirm}
        opensConfirmModal={opensConfirmModal}
        setOpensConfirmModal={setOpensConfirmModal}
      />

      {ResponseHandlerOfSelectQuotationPartner}
    </>
  );
}
