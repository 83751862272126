import { useMemo } from "react";
import { useAtom } from "jotai";
import { FORWARDING_ACCOUNT_ATOMS } from "jotaiStates/account";

import { useDebounce } from "@sellernote/shared/src/utils/common/hook";

export default function useGetAccountParams() {
  const [filter, setFilter] = useAtom(
    FORWARDING_ACCOUNT_ATOMS.ACCOUNT_TABLE_FILTER
  );

  const {
    page,
    perPage,
    id,
    businessArea,
    category,
    paymentCondition,
    searchOption: { name, BRN, representativeName, teamName, partnerName },
  } = filter;

  const debouncedId = useDebounce(id, 1000);
  const debouncedName = useDebounce(name, 1000);
  const debouncedBRN = useDebounce(BRN, 1000);
  const debouncedRepresentativeName = useDebounce(representativeName, 1000);
  const debouncedTeamName = useDebounce(teamName, 1000);
  const debouncedPartnerName = useDebounce(partnerName, 1000);

  const params = useMemo(() => {
    return {
      page,
      perPage,
      ...(id ? { id: debouncedId } : {}),
      ...(businessArea ? { businessArea } : {}),
      ...(category ? { category } : {}),
      ...(paymentCondition ? { paymentCondition } : {}),
      ...(name ? { name: debouncedName } : {}),
      ...(BRN ? { BRN: debouncedBRN } : {}),
      ...(representativeName
        ? { representativeName: debouncedRepresentativeName }
        : {}),
      ...(debouncedTeamName ? { teamName: debouncedTeamName } : {}),
      ...(debouncedPartnerName ? { partnerName: debouncedPartnerName } : {}),
    };
  }, [
    BRN,
    businessArea,
    category,
    debouncedBRN,
    debouncedId,
    debouncedName,
    debouncedPartnerName,
    debouncedRepresentativeName,
    debouncedTeamName,
    id,
    name,
    page,
    paymentCondition,
    perPage,
    representativeName,
  ]);

  return { params };
}
