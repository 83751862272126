import { Control } from "react-hook-form";
import { Box, colors, Grid, Typography } from "@mui/material";

import SADSTable, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/shared/src/admin-ui/components/SADSTable";
import TextFieldWithReactHookForm from "@sellernote/shared/src/admin-ui/components/TextFieldWithReactHookForm";
import {
  AdminBidCopyContactingPartnerForm,
  AdminBidDetail,
  ConsolidationItemList,
} from "@sellernote/shared/src/types/forwarding/adminBid";
import { checkBidPartnerLanguageIsEn } from "@sellernote/shared/src/utils/forwarding/admin/adminBid";

type CellKey = "품명" | "품명(영문)";

const headCellList: TableHeadCell<CellKey>[] = [
  { cellKey: "품명", label: "품명", width: 300 },
  { cellKey: "품명(영문)", label: "품명(영문)", width: 300 },
];

export default function CargoForConsol({
  bidDetail,
  sellerList,
  control,
}: {
  bidDetail: AdminBidDetail;
  sellerList: ConsolidationItemList[] | undefined;
  control: Control<AdminBidCopyContactingPartnerForm>;
}) {
  const createBodyRowList = ({
    sellerData,
    consolidationItemsIndex,
  }: {
    sellerData: ConsolidationItemList;
    consolidationItemsIndex: number;
  }): TableBodyRow<CellKey>[] => {
    return sellerData.itemList.map((item, itemIndex) => {
      return {
        rowKey: item.id,
        품명: {
          value: (
            <Grid container>
              {(!checkBidPartnerLanguageIsEn(bidDetail, "foreign") ||
                !checkBidPartnerLanguageIsEn(bidDetail, "domestic")) && (
                <TextFieldWithReactHookForm
                  fullWidth
                  name={`consolidationFreightInfo.${consolidationItemsIndex}.${itemIndex}.item.itemName`}
                  control={control}
                />
              )}
            </Grid>
          ),
        },
        "품명(영문)": {
          value: (
            <Grid container>
              {(checkBidPartnerLanguageIsEn(bidDetail, "foreign") ||
                checkBidPartnerLanguageIsEn(bidDetail, "domestic")) && (
                <TextFieldWithReactHookForm
                  fullWidth
                  name={`consolidationFreightInfo.${consolidationItemsIndex}.${itemIndex}.item.itemNameEn`}
                  control={control}
                  required={true}
                />
              )}
            </Grid>
          ),
        },
      };
    });
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
        {sellerList?.map((sellerData, consolidationItemsIndex) => {
          const bodyRowList = createBodyRowList({
            sellerData,
            consolidationItemsIndex,
          });

          return (
            <Box key={consolidationItemsIndex}>
              <Typography
                variant="subtitle1"
                component="div"
                color={colors.blue[600]}
              >
                {`수출자${consolidationItemsIndex + 1}`}
              </Typography>

              <SADSTable
                headCellList={headCellList}
                bodyRowList={bodyRowList}
              />
            </Box>
          );
        })}
      </Box>
    </>
  );
}
