import { ItemUnitMeasurement } from "@sellernote/shared/src/types/common/common";
import { ShipmentItem } from "@sellernote/shared/src/types/forwarding/adminBid";

/** LCL, AIR에서 추가로 사용되는 포장 타입 */
function checkOnlyForLCLAndAirPackage(measurement: ItemUnitMeasurement) {
  return (
    measurement === "BOX" ||
    measurement === "PALLET" ||
    measurement === "CRATE" ||
    measurement === "DRUM"
  );
}

/**
 * 추가된 항목의 견적단위와 현재 의뢰 상품의 포장타입이 같은지 확인
 * 견적단위, 포장단위가 일치하는 품목의 갯수를 리턴
 */
function checkIsSamePackageInProductList({
  shipmentItemList,
  itemUnitMeasurement,
}: {
  shipmentItemList: ShipmentItem[];
  itemUnitMeasurement: ItemUnitMeasurement;
}) {
  return (
    shipmentItemList.reduce((count, { packingType }) => {
      // 서버에서 복수상태인 value값 수정이 어려워 프론트에서만 임의로 단수인 상태 비교 시 's' 를지우기 위해 slice사용
      const newPackingType =
        packingType === "boxes" ? "box" : packingType?.slice(0, -1);

      return newPackingType === itemUnitMeasurement.toLowerCase()
        ? count + 1
        : count;
    }, 0) ?? 0
  );
}

/**
 * 운송의뢰의 단위와 견적항목의 단위가 다를때, 견적항목의 단위를 가진 운송의뢰 물품이 1개 이상일 때 0을 리턴(수량의 들어가는 값. 추후에 null로 변환되어 빈칸처리됨)
 * 일치하는 포장타입의 물품 갯수가 1개라면, 해당 물품의 수량을 리턴함
 */
function getQuantityForSameLCLAndAIRPackageItem({
  shipmentItemList,
  itemUnitMeasurement,
  numberOfProductWithSamePackageType,
}: {
  shipmentItemList: ShipmentItem[];
  itemUnitMeasurement: ItemUnitMeasurement;
  numberOfProductWithSamePackageType: number;
}) {
  if (numberOfProductWithSamePackageType > 1) return 0;

  return (
    shipmentItemList?.find(({ packingType }) => {
      return packingType === "boxes"
        ? "box" === itemUnitMeasurement.toLowerCase()
        : // 서버에서 복수상태인 value값 수정이 어려워 프론트에서만 임의로 단수인 상태 비교 시 's' 를지우기 위해 slice사용
          packingType?.slice(0, -1) === itemUnitMeasurement.toLowerCase();
    })?.quantity ?? 0
  );
}

// 최신환율 견적가와 이전 견적가가 같은지 확인해주는 함수
function checkPriceDifference({
  itemPrice,
  previousItemPrice,
}: {
  itemPrice: number;
  previousItemPrice?: number;
}) {
  if (!previousItemPrice) return false;

  return itemPrice !== previousItemPrice;
}

export {
  checkOnlyForLCLAndAirPackage,
  checkIsSamePackageInProductList,
  getQuantityForSameLCLAndAIRPackageItem,
  checkPriceDifference,
};
