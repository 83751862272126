import { ChangeEvent } from "react";
import { useFormContext } from "react-hook-form";
import { Grid, Typography } from "@mui/material";

import RadioGroupWithReactHookForm, {
  RadioGroupWithReactHookFormOption,
} from "@sellernote/shared/src/admin-ui/components/RadioGroupWithReactHookForm";
import { inlandTransportTypeRadioGroupOptionList } from "@sellernote/shared/src/admin-ui/pageContainers/bid/constants";
import { BooleanStringV2 } from "@sellernote/shared/src/types/common/common";
import {
  BidCreateCheckPoints,
  BidCreateFormData,
} from "@sellernote/shared/src/types/forwarding/adminBidCreate";

import { getCheckPointLabel } from "../utils";

const isContainRadioGroupOptions: RadioGroupWithReactHookFormOption<BooleanStringV2>[] =
  [
    {
      label: "포함",
      value: "TRUE",
    },
    {
      label: "미포함",
      value: "FALSE",
    },
  ];

const containerAccessableRadioGroupOptions: RadioGroupWithReactHookFormOption<BooleanStringV2>[] =
  [
    {
      label: "가능",
      value: "TRUE",
    },
    {
      label: "불가능",
      value: "FALSE",
    },
  ];

const containerStuffingRadioGroupOptions: RadioGroupWithReactHookFormOption<BooleanStringV2>[] =
  [
    {
      label: "예",
      value: "TRUE",
    },
    {
      label: "아니오",
      value: "FALSE",
    },
  ];

/**
 * TODO: 어디로 분리할까.. (추후 분리 예정)
 */
function getCheckPointRadioGroupOption(checkPoint: BidCreateCheckPoints) {
  switch (checkPoint) {
    case "containerAccessable":
      return containerAccessableRadioGroupOptions;
    /** AIR 터미널 입고 에서 대행(쉽다)일 때는 운송방식의 해당없음이 없어야 함 */
    case "inlandTransportType":
      return inlandTransportTypeRadioGroupOptionList;
    case "containerStuffing":
      return containerStuffingRadioGroupOptions;
    default:
      return isContainRadioGroupOptions;
  }
}

export default function CheckPointItem({
  checkPoint,
  disabled,
  handleEffectOnChange,
}: {
  checkPoint: BidCreateCheckPoints;
  disabled?: boolean;
  handleEffectOnChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}) {
  const { control, watch } = useFormContext<BidCreateFormData>();

  return (
    <Grid item container spacing={3} alignItems="center">
      <Grid item xs={2}>
        <Typography variant="subtitle2" component="span">
          {getCheckPointLabel(checkPoint)}
        </Typography>
      </Grid>

      <Grid item>
        <RadioGroupWithReactHookForm
          name={checkPoint}
          control={control}
          radioGroupOption={getCheckPointRadioGroupOption(checkPoint)}
          disabled={disabled}
          handleEffectOnChange={handleEffectOnChange}
        />
      </Grid>
    </Grid>
  );
}
