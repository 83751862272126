import { ShipmentType } from "@sellernote/shared/src/types/common/common";
import {
  BidCopyStatus,
  BidCopyTargetRecordForExportation,
  BidCopyTargetRecordForImportation,
} from "@sellernote/shared/src/types/forwarding/adminBid";
import {
  BidProjectStatus,
  BidStatus,
} from "@sellernote/shared/src/types/forwarding/bid";

/**
 * (수입용) copyStatus에 따른 복사 대상 Record 기본값
 */
function getDefaultCopyTargetRecordForImportation(
  copyStatus: BidCopyStatus,
  hasExporterInfo: boolean
): BidCopyTargetRecordForImportation {
  let checkBoxValues = {
    defaultInfo: true,
    copyEstimatePartner: false,
    contactEstimatePartner: false,
    copyEstimate: false,
    submitEstimate: false,
    acceptEstimate: false,
    copyExporterInfo: false,
    copyBookingPartner: false,
    contactBookingPartner: false,
  };

  if (copyStatus === "waiting") {
    checkBoxValues = {
      ...checkBoxValues,
      copyEstimate: true,
      submitEstimate: true,
    };
  }

  if (copyStatus === "waitingForExporterInfo") {
    checkBoxValues = {
      ...checkBoxValues,
      copyEstimate: true,
      submitEstimate: true,
      acceptEstimate: true,
    };
  }

  // 수출일때 수출자 컨택 전, 중 대신 사용하는 화물 준비 중 상태
  if (copyStatus === "waitingFreight") {
    checkBoxValues = {
      ...checkBoxValues,
      copyEstimate: true,
      submitEstimate: true,
      acceptEstimate: true,
    };
  }

  if (copyStatus === "beforeContactPartner") {
    checkBoxValues = {
      ...checkBoxValues,
      copyEstimate: true,
      submitEstimate: true,
      acceptEstimate: true,
      copyExporterInfo: hasExporterInfo,
    };
  }

  if (copyStatus === "contactingPartner") {
    checkBoxValues = {
      ...checkBoxValues,
      copyEstimate: true,
      submitEstimate: true,
      acceptEstimate: true,
      copyExporterInfo: hasExporterInfo,
      copyBookingPartner: true,
      contactBookingPartner: true,
    };
  }

  return checkBoxValues;
}

/**
 * (수출용) copyStatus에 따른 복사 대상 Record 기본값
 */
function getDefaultCopyTargetRecordForExportation(
  copyStatus: BidCopyStatus
): BidCopyTargetRecordForExportation {
  let checkBoxValues = {
    defaultInfo: true,
    copyEstimatePartner: false,
    contactEstimatePartner: false,
    copyEstimate: false,
    submitEstimate: false,
    acceptEstimate: false,
    copyExporterInfo: false,
    copyBookingPartner: false,
    contactBookingPartner: false,
    copyLocalPartner: false,
    contactLocalPartner: false,
  };

  if (copyStatus === "waiting") {
    checkBoxValues = {
      ...checkBoxValues,
      copyEstimate: true,
      submitEstimate: true,
    };
  }

  if (copyStatus === "waitingForExporterInfo") {
    checkBoxValues = {
      ...checkBoxValues,
      copyEstimate: true,
      submitEstimate: true,
      acceptEstimate: true,
    };
  }

  // 수출일때 수출자 컨택 전, 중 대신 사용하는 화물 준비 중 상태
  if (copyStatus === "waitingFreight") {
    checkBoxValues = {
      ...checkBoxValues,
      copyEstimate: true,
      submitEstimate: true,
      acceptEstimate: true,
    };
  }

  return checkBoxValues;
}

function getInitialCopyStatusForImportation({
  status,
  projectStatus,
}: {
  status: BidStatus;
  projectStatus: BidProjectStatus;
}): BidCopyStatus {
  if (status === "inProgress") {
    return projectStatus === "beforeContactPartner" ||
      projectStatus === "contactingPartner"
      ? projectStatus
      : "contactingPartner";
  }

  if (status === "finished") {
    return projectStatus === "canceled" || projectStatus === "failed"
      ? "committed"
      : "contactingPartner";
  }

  // 기존 코드가 이렇게 return하여 타입단언으로 처리함
  return status as BidCopyStatus;
}

function getInitialCopyStatusForExportation({
  status,
  projectStatus,
}: {
  status: BidStatus;
  projectStatus: BidProjectStatus;
}): BidCopyStatus {
  if (status === "inProgress") return "waitingFreight";

  if (status === "finished") {
    return projectStatus === "canceled" || projectStatus === "failed"
      ? "committed"
      : "waitingFreight";
  }

  // 기존 코드가 이렇게 return하여 타입단언으로 처리함
  return status as BidCopyStatus;
}

function getCopyStatusOptionListForImportation(
  initialCopyStatus: BidCopyStatus
): {
  name: BidCopyStatus;
  label: string;
  disabled?: boolean;
}[] {
  const optionList = [
    {
      name: "committed",
      label: "의뢰 받음",
    },
    {
      name: "estimating",
      label: "견적 산출 중",
    },
    {
      name: "waiting",
      label: "사용자 선택 대기 중",
    },
    {
      name: "waitingForExporterInfo",
      label: "수출자 정보 입력 대기 중",
    },
    {
      name: "beforeContactPartner",
      label: "수출자 컨택 전",
    },
    {
      name: "contactingPartner",
      label: "수출자 컨택 중",
    },
  ] as const;

  return optionList.map((option) => ({
    ...option,
    // disabled 상태를 주입
    disabled: checkIsDisabledCopyStatus({
      copyStatus: option.name,
      shipmentType: "importation",
      initialCopyStatus,
    }),
  }));
}

function getCopyStatusOptionListForExportation(
  initialCopyStatus: BidCopyStatus
): {
  name: BidCopyStatus;
  label: string;
  disabled?: boolean;
}[] {
  const optionList = [
    {
      name: "committed",
      label: "의뢰 받음",
    },
    {
      name: "estimating",
      label: "견적 산출 중",
    },
    {
      name: "waiting",
      label: "사용자 선택 대기 중",
    },
    {
      name: "waitingFreight",
      label: "화물 준비 중",
    },
  ] as const;

  return optionList.map((option) => ({
    ...option,
    // disabled 상태를 주입
    disabled: checkIsDisabledCopyStatus({
      copyStatus: option.name,
      shipmentType: "exportation",
      initialCopyStatus,
    }),
  }));
}

/**
 * 선택불가능한 copyStatus인지 체크
 *
 * <TODO: 개선필요>
 * - disabled를 체크하는 방식이라 인지적으로 불편함
 * - "initialCopyStatus에서는 어떤 status들을 산택가능 하다"라는 식으로 표현하는 방식이 더 직관적일 것으로 보임
 */
function checkIsDisabledCopyStatus({
  copyStatus,
  shipmentType,
  initialCopyStatus,
}: {
  copyStatus: BidCopyStatus;
  shipmentType: ShipmentType;
  initialCopyStatus: BidCopyStatus;
}) {
  if (initialCopyStatus === "committed" || initialCopyStatus === "estimating") {
    if (copyStatus === "committed" || copyStatus === "estimating") {
      return false;
    }
    return true;
  }

  if (initialCopyStatus === "waiting") {
    if (
      copyStatus === "committed" ||
      copyStatus === "estimating" ||
      copyStatus === "waiting"
    ) {
      return false;
    }
    return true;
  }

  if (
    shipmentType === "importation" &&
    initialCopyStatus === "waitingForExporterInfo"
  ) {
    if (
      copyStatus === "beforeContactPartner" ||
      copyStatus === "contactingPartner"
    ) {
      return true;
    }
    return false;
  }

  if (
    shipmentType === "importation" &&
    initialCopyStatus === "beforeContactPartner"
  ) {
    if (copyStatus === "contactingPartner") {
      return true;
    }

    return false;
  }

  return false;
}

/**
 * (수입용) 의뢰복사 대상 옵션 리스트를 반환한다.
 */
function getBidCopyTargetOptionListForImportation(
  selectedCopyStatus: BidCopyStatus
): {
  label: string;
  value: keyof BidCopyTargetRecordForImportation;
  disabled?: boolean;
}[] {
  const optionList = [
    {
      value: "defaultInfo",
      label: "기본 정보, 운송 정보(운영/체크포인트), 물품 상세",
    },
    {
      value: "copyEstimatePartner",
      label: "견적파트너",
    },
    {
      value: "contactEstimatePartner",
      label: "견적파트너 컨택",
    },
    {
      value: "copyEstimate",
      label: "견적서",
    },
    {
      value: "submitEstimate",
      label: "견적서 제출",
    },
    {
      value: "acceptEstimate",
      label: "견적서 수락",
    },
    {
      value: "copyExporterInfo",
      label: "수출자 정보",
    },
    {
      value: "copyBookingPartner",
      label: "지급유형, 부킹파트너",
    },
    {
      value: "contactBookingPartner",
      label: "부킹파트너 컨택",
    },
  ] as const;

  return optionList.map((option) => ({
    ...option,
    // disabled 상태를 주입
    disabled: !checkIsSelectableCopyTargetOptionForImportation({
      copyTarget: option.value,
      selectedCopyStatus,
    }),
  }));
}

/**
 * (수출용) 의뢰복사 대상 옵션 리스트를 반환한다.
 */
function getBidCopyTargetOptionListForExportation(
  selectedCopyStatus: BidCopyStatus,
  hasExporterInfo: boolean
): {
  label: string;
  value: keyof BidCopyTargetRecordForExportation;
  disabled?: boolean;
}[] {
  const optionList = [
    {
      value: "defaultInfo",
      label: "기본 정보, 운송 정보(운영/체크포인트), 물품 상세",
    },
    {
      value: "copyEstimatePartner",
      label: "견적파트너",
    },
    {
      value: "contactEstimatePartner",
      label: "견적파트너 컨택",
    },
    {
      value: "copyEstimate",
      label: "견적서",
    },
    {
      value: "submitEstimate",
      label: "견적서 제출",
    },
    {
      value: "acceptEstimate",
      label: "견적서 수락",
    },
    {
      value: "copyExporterInfo",
      label: "수입자 정보",
    },
    {
      value: "copyBookingPartner",
      label: "지급유형, 부킹파트너",
    },
    {
      value: "contactBookingPartner",
      label: "부킹파트너 컨택",
    },
    {
      value: "copyLocalPartner",
      label: "지급유형, 현지파트너",
    },
    {
      value: "contactLocalPartner",
      label: "현지파트너 컨택",
    },
  ] as const;

  return optionList.map((option) => ({
    ...option,
    // disabled 상태를 주입
    disabled: !checkIsSelectableCopyTargetOptionForExportation({
      copyTarget: option.value,
      selectedCopyStatus,
      hasExporterInfo,
    }),
  }));
}

/**
 * (수입용) 선택택 copyStatus에서 변경가능한 copyTarget인지 확인
 */
function checkIsSelectableCopyTargetOptionForImportation({
  copyTarget,
  selectedCopyStatus,
}: {
  copyTarget: keyof BidCopyTargetRecordForImportation;
  selectedCopyStatus: BidCopyStatus;
}): boolean {
  switch (copyTarget) {
    case "copyEstimatePartner": {
      if (selectedCopyStatus !== "committed") {
        return true;
      }

      return false;
    }

    case "contactEstimatePartner": {
      return selectedCopyStatus === "estimating";
    }

    case "copyBookingPartner": {
      return selectedCopyStatus === "beforeContactPartner";
    }

    case "contactBookingPartner": {
      if (selectedCopyStatus === "contactingPartner") {
        /** 수입운송의 경우, 부킹 파트너 컨택 없이 의뢰복사 가능 */
        return true;
      }

      return false;
    }

    default: {
      return false;
    }
  }
}

/**
 * (수출용) 선택된 copyStatus에서 변경가능한 copyTarget인지 확인
 */
function checkIsSelectableCopyTargetOptionForExportation({
  copyTarget,
  selectedCopyStatus,
  hasExporterInfo,
}: {
  copyTarget: keyof BidCopyTargetRecordForExportation;
  selectedCopyStatus: BidCopyStatus;
  hasExporterInfo: boolean;
}): boolean {
  switch (copyTarget) {
    case "copyEstimatePartner": {
      if (selectedCopyStatus !== "committed") {
        return true;
      }

      return false;
    }

    case "contactEstimatePartner": {
      return selectedCopyStatus === "estimating";
    }

    case "copyExporterInfo": {
      if (!hasExporterInfo) {
        /**
         * 수입자 정보가 없으면 선택불가함
         * - 참고: 초기화 로직부분은 다른 함수(`getDefaultCopyTargetRecordForImportation`에서 관리
         */
        return false;
      }

      return selectedCopyStatus === "waitingFreight";
    }

    case "copyBookingPartner": {
      // 사용자 선택대기중 상태와 화물 준비중상태에서 활성화 됨
      if (
        selectedCopyStatus === "waiting" ||
        selectedCopyStatus === "waitingFreight"
      ) {
        return true;
      }
      return false;
    }

    case "contactBookingPartner": {
      // 화물 준비중상태에서 활성화
      if (selectedCopyStatus === "waitingFreight") {
        return true;
      }

      return false;
    }

    case "copyLocalPartner": {
      // 화물 준비중상태에서 활성화
      if (selectedCopyStatus === "waitingFreight") {
        return true;
      }

      return false;
    }

    default: {
      return false;
    }
  }
}

export {
  getDefaultCopyTargetRecordForImportation,
  getDefaultCopyTargetRecordForExportation,
  getInitialCopyStatusForImportation,
  getInitialCopyStatusForExportation,
  getCopyStatusOptionListForImportation,
  getCopyStatusOptionListForExportation,
  getBidCopyTargetOptionListForImportation,
  getBidCopyTargetOptionListForExportation,
};
