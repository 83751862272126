import { FieldValues } from "react-hook-form";

import {
  BooleanStringV2,
  FreightType,
  Port,
} from "@sellernote/shared/src/types/common/common";
import {
  AdminShipmentInfoForm,
  AdminShipmentInfoFormDirty,
  ExportShipmentInfoUpdate,
  ImportShipmentInfoUpdate,
} from "@sellernote/shared/src/types/forwarding/adminBid";
import { BidTransportType } from "@sellernote/shared/src/types/forwarding/bid";

import { exportCheckpointList, importCheckpointList } from "./constants";

const getPortNameOptionList = ({
  transportType,
  portList,
  freightType,
  isExport,
  isEndPort,
}: {
  transportType: BidTransportType;
  portList: Port[];
  freightType: FreightType;
  isExport: boolean;
  isEndPort: boolean;
}) => {
  // 중복된 항구명을 제거하기 위해 Set을 사용
  return [
    ...new Set(
      portList
        .filter((portItem) => {
          if (transportType === "inland") {
            if (freightType === "AIR") {
              return portItem.type === "air";
            }
            return portItem.type === "sea";
          }
          return transportType === portItem.type;
        })
        .filter((portItem) => {
          if (isExport) {
            // 도착지는 등록 국가 제외를 전부 보여준다. 현재는 KR로 고정
            if (isEndPort) {
              return portItem.country !== "KR";
            }
            // 수출 출발지는 등록 국가만 보여준다. 현재는 KR로 고정
            return portItem.country === "KR";
          }
          return portItem;
        })
        .map((portItem) => {
          return {
            label: `${portItem.nameEN}(${portItem.name})`,
            value: portItem.id,
          };
        })
    ),
  ];
};

const getIncotermsDefaultValueAndOptionList = ({
  freightType,
  isExport,
  endType,
  hopeCargoInsurance,
  importCustoms,
}: {
  freightType: FreightType;
  isExport: boolean;
  endType: BidTransportType | undefined;
  hopeCargoInsurance?: boolean;
  importCustoms?: boolean;
}) => {
  if (isExport) {
    // 도착지 내륙이 아닐 떄
    if (endType !== "inland") {
      // 적하보험 신청시
      if (hopeCargoInsurance) {
        if (freightType === "AIR") {
          return { defaultValue: "CIP", options: ["CIP"] };
        }
        return { defaultValue: "CIF", options: ["CIF"] };
      }
      // 적하보험 미신청시
      if (freightType === "AIR") {
        return { defaultValue: "CPT", options: ["CPT"] };
      }
      return { defaultValue: "CFR", options: ["CFR"] };
    }

    // 도착지 내륙이고 수입통관 신청여부 일 때
    if (importCustoms) {
      return { defaultValue: "DDP", options: ["DDP"] };
    }
    return { defaultValue: "DAP", options: ["DAP"] };
  }

  // 운영팀의 요청으로 수입에서 인코텀즈는 조건없이 리스트함
  return {
    defaultValue: "FOB",
    options: ["FOB", "EXW", "FCA", "CPT", "CIP", "CFR", "CIF", "DDP", "DAP"],
  };
};

/** form 데이터에서 수정된(dirty값이 true인) 항목만 추출  */
function getFormWithDirty({
  dirtyFields,
  formData,
}: {
  dirtyFields: AdminShipmentInfoFormDirty;
  formData: AdminShipmentInfoForm;
}) {
  if (!dirtyFields) return undefined;

  let formItemWithDirty: Partial<AdminShipmentInfoForm> = {};

  for (const dirtyFormKey in dirtyFields) {
    if (
      dirtyFormKey in formData &&
      dirtyFields[dirtyFormKey as keyof AdminShipmentInfoForm] === true
    ) {
      formItemWithDirty = {
        ...formItemWithDirty,
        [dirtyFormKey]: formData[dirtyFormKey as keyof AdminShipmentInfoForm],
      };
    }
  }

  /** 수정한 값이 없다면 undefined를 리턴 */
  if (Object.keys(formItemWithDirty).length === 0) return undefined;

  return formItemWithDirty;
}

function getImportShipmentUpdatePayload(
  formData: Partial<AdminShipmentInfoForm> | undefined
): ImportShipmentInfoUpdate | undefined {
  if (!formData) return;

  let importShipmentUpdatePayload: ImportShipmentInfoUpdate = {};

  for (const key in formData) {
    // 수출에서 사용하는 체크포인트인지 확인하고 endType, startType은 payload에 담지 않는다.
    if (
      !exportCheckpointList.includes(key) &&
      key !== "startType" &&
      key !== "endType"
    ) {
      const keyValue = formData[key as keyof ImportShipmentInfoUpdate];
      importShipmentUpdatePayload = {
        ...importShipmentUpdatePayload,
        // booleanString으로 되어있는 값들을 request로 보내기 위해 실제 boolean 값으로 변경
        [key]:
          keyValue === "TRUE" ? true : keyValue === "FALSE" ? false : keyValue,
      };
    }
  }

  return importShipmentUpdatePayload;
}

function getBooleanStringV2(value: boolean | null): BooleanStringV2 | null {
  if (value === null) return null;

  return value ? "TRUE" : "FALSE";
}

function getExportShipmentUpdatePayload(
  formData: Partial<AdminShipmentInfoForm> | undefined
): ExportShipmentInfoUpdate | undefined {
  if (!formData) return;

  let exportShipmentUpdatePayload: ExportShipmentInfoUpdate = {};

  for (const key in formData) {
    // 수입에서 사용하는 체크포인트인지 확인하고 endType, startType은 payload에 담지 않는다.
    if (
      !importCheckpointList.includes(key) &&
      key !== "startType" &&
      key !== "endType"
    ) {
      const keyValue = formData[key as keyof ExportShipmentInfoUpdate];
      exportShipmentUpdatePayload = {
        ...exportShipmentUpdatePayload,
        // booleanString으로 되어있는 값들을 request로 보내기 위해 실제 boolean 값으로 변경
        [key]:
          keyValue === "TRUE" ? true : keyValue === "FALSE" ? false : keyValue,
      };
    }
  }

  return exportShipmentUpdatePayload;
}

function getAutoCompleteValidate(value: FieldValues) {
  /** 출/도착지 항구/공항을 선택하지 않고 입력하는 경우 value가 number가 아니다. */
  return !isNaN(Number(value)) || "선택을 하지 않았습니다.";
}

export {
  getPortNameOptionList,
  getIncotermsDefaultValueAndOptionList,
  getFormWithDirty,
  getImportShipmentUpdatePayload,
  getBooleanStringV2,
  getExportShipmentUpdatePayload,
  getAutoCompleteValidate,
};
