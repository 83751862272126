import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";

import {
  GET_ADMIN_BID_APPLY_ITEMS_RES,
  GET_ADMIN_BID_DETAIL_RES,
} from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminBid";
import useGetNewCurrencyQuotation from "@sellernote/shared/src/hooks/admin/useGetNewCurrencyQuotation";
import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import ADMIN_BID_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import { Liner } from "@sellernote/shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/shared/src/types/forwarding/adminBid";
import { ExchangeRate } from "@sellernote/shared/src/types/forwarding/trello";
import {
  getInlandType,
  getLinerId,
  getQuotationDataCurrency,
  replaceBrTagsWithNewline,
} from "@sellernote/shared/src/utils/forwarding/admin/adminBid";
import {
  findExchangeRate,
  getAllTotalItemPriceOfFeeData,
} from "@sellernote/shared/src/utils/forwarding/admin/tradingStatement";

import QuotationForm from "pages/bid/_components/QuotationForm";

import QuotationConfirm from "../../QuotationDesc/QuotationConfirm";

function DraftQuotation({
  bidDetail,
  exchangeRateList,
  applyItems,
  linerList,
}: {
  bidDetail: GET_ADMIN_BID_DETAIL_RES;
  exchangeRateList: ExchangeRate[];
  applyItems: GET_ADMIN_BID_APPLY_ITEMS_RES;
  linerList: Liner[];
}) {
  const history = useHistory();

  const { handleSnackbarOpen } = useSnackbar();

  const readonly = bidDetail.quotationsUser.length > 0;

  const {
    mutateAsync: editBidQuoteDraft,
    isLoading,
    ResponseHandler: ResponseHandlerOfEditBidQuoteDraft,
  } = ADMIN_BID_QUERY.useEditBidQuoteDraft();

  const {
    mutateAsync: editExportBidQuoteDraft,
    isLoading: isExportLoading,
    ResponseHandler: ResponseHandlerOfEditExportBidQuoteDraft,
  } = ADMIN_BID_QUERY.useEditExportBidQuoteDraft();

  const { quotationWithIsTaxExempt } = useGetNewCurrencyQuotation({
    bidDetail,
    exchangeRateList,
    type: "draft",
  });

  const methods = useForm<ApplyBidFormData>({
    defaultValues: {
      otherFeeData: quotationWithIsTaxExempt.otherFee || [],
      freightPaymentType: quotationWithIsTaxExempt.freightPaymentType,
      liner: quotationWithIsTaxExempt.liner,
      freightFeeData: quotationWithIsTaxExempt.freightFee || [],
      domesticFeeData: quotationWithIsTaxExempt.domesticFee || [],
      inlandFeeData: quotationWithIsTaxExempt.inlandFee || [],
      localFeeData: quotationWithIsTaxExempt.localFee || [],
      taxFeeData: quotationWithIsTaxExempt.taxFee || [],
      leadtime: quotationWithIsTaxExempt.leadtime,
      comment: replaceBrTagsWithNewline(quotationWithIsTaxExempt.comment),
      expiredAt: quotationWithIsTaxExempt.expiredAt,
      isTransit: quotationWithIsTaxExempt.isTransit,
    },
  });

  const { watch } = methods;

  const handleQuotationForwardersChange = useCallback(async () => {
    if (!bidDetail.quotationForwarders) {
      return;
    }
    const editBidQuoteDraftMutation = bidDetail.isImport
      ? editBidQuoteDraft
      : editExportBidQuoteDraft;

    return editBidQuoteDraftMutation(
      {
        pathParams: {
          bidId: bidDetail.id,
          //견적서 데이터 중 가장 앞에 있는 데이터를 사용한다.
          quotationId: bidDetail.quotationForwarders[0].id,
        },
        quotationData: {
          ...bidDetail.quotationForwarders[0],
          freightPaymentType: watch("freightPaymentType"),
          liner: watch("liner"),
          isTransit: watch("isTransit"),
          exchangeRate: findExchangeRate(exchangeRateList, "USD"),
          totalPrice: getAllTotalItemPriceOfFeeData(
            watch("freightFeeData"),
            watch("domesticFeeData"),
            watch("localFeeData"),
            watch("inlandFeeData"),
            watch("otherFeeData"),
            watch("taxFeeData")
          ),
          freightFee: watch("freightFeeData"),
          localFee: watch("localFeeData"),
          domesticFee: watch("domesticFeeData"),
          inlandFee: watch("inlandFeeData"),
          taxFee: watch("taxFeeData"),
          otherFee: watch("otherFeeData"),
          comment: watch("comment"),
          expiredAt: watch("expiredAt"),
          leadtime: watch("leadtime"),
          currency: getQuotationDataCurrency({
            localFee: watch("localFeeData"),
            freightFee: watch("freightFeeData"),
            domesticFee: watch("domesticFeeData"),
            inlandFee: watch("inlandFeeData"),
            otherFee: watch("otherFeeData"),
            taxFee: watch("taxFeeData"),
            exchangeRateList: exchangeRateList,
          }),
          linerId: getLinerId(linerList, watch("liner")),
        },

        // 수출 요청에서는 자동적으로 inlandType을 설정해줌
        inlandType: bidDetail.isImport
          ? getInlandType(watch("inlandFeeData"), bidDetail.freightType)
          : undefined,
      },
      {
        onSuccess() {
          handleSnackbarOpen("수정 완료되었습니다.");
        },
      }
    );
  }, [
    bidDetail.freightType,
    bidDetail.id,
    bidDetail.isImport,
    bidDetail.quotationForwarders,
    editBidQuoteDraft,
    editExportBidQuoteDraft,
    exchangeRateList,
    handleSnackbarOpen,
    linerList,
    watch,
  ]);

  return (
    <FormProvider {...methods}>
      <QuotationForm
        bidDetail={bidDetail}
        freightType={bidDetail.freightType}
        exchangeRateList={exchangeRateList}
        applyItems={applyItems}
        linerList={linerList}
        readonly={readonly}
        onSubmit={handleQuotationForwardersChange}
      >
        <Button
          variant="contained"
          type="submit"
          disabled={isLoading || isExportLoading}
        >
          수정
        </Button>

        {bidDetail.quotationForwarders.length > 0 && (
          <QuotationConfirm
            bidId={bidDetail.id}
            quotationId={bidDetail.quotationForwarders[0].id}
            totalPrice={getAllTotalItemPriceOfFeeData(
              watch("freightFeeData"),
              watch("domesticFeeData"),
              watch("localFeeData"),
              watch("inlandFeeData"),
              watch("otherFeeData"),
              watch("taxFeeData")
            )}
            isImport={bidDetail.isImport}
            onBeforeUpdate={handleQuotationForwardersChange}
            onAfterUpdate={() => history.replace(`/bid/detail/${bidDetail.id}`)}
          />
        )}
      </QuotationForm>

      {ResponseHandlerOfEditBidQuoteDraft}
      {ResponseHandlerOfEditExportBidQuoteDraft}
    </FormProvider>
  );
}

export default DraftQuotation;
