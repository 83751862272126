import { useFormContext } from "react-hook-form";
import { Grid } from "@mui/material";

import { BidCreateFormData } from "@sellernote/shared/src/types/forwarding/adminBidCreate";

import CheckPointItem from "./_components/CheckPointItem";

export default function FCLCheckPoint() {
  const { watch } = useFormContext<BidCreateFormData>();

  const { bidType, endType, startType, incoterms } = watch();

  return (
    <Grid item container direction={"column"} xs={11}>
      {bidType === "import" && (
        <>
          <CheckPointItem checkPoint="containLss" />

          <CheckPointItem checkPoint="containOceanSurcharge" />

          <CheckPointItem checkPoint="containDomesticFee" />

          {endType === "inland" && (
            <CheckPointItem checkPoint="containerAccessable" />
          )}
        </>
      )}

      {bidType === "export" && (
        <>
          {startType === "inland" && (
            <CheckPointItem checkPoint="containerStuffing" />
          )}
        </>
      )}
    </Grid>
  );
}
