import { Grid } from "@mui/material";

import { ShipmentCreateContainersInfo } from "@sellernote/shared/src/types/forwarding/adminBidCreate";
import { getFEUByContainerType } from "@sellernote/shared/src/utils/common/number";

import FCLItemList from "@sellernote/shared/src/admin-ui/components/CargoInfo/GeneralCargoInfo/FCLItemList";
import { TableHeadCell } from "@sellernote/shared/src/admin-ui/components/SADSTable";

type CellKey = "NO" | "품명" | "컨테이너" | "위험물";

const headCellList: TableHeadCell<CellKey>[] = [
  { cellKey: "NO", label: "NO", align: "right", width: 70 },
  { cellKey: "품명", label: "품명", width: 200 },
  { cellKey: "컨테이너", label: "컨테이너", align: "right", width: 100 },
  { cellKey: "위험물", label: "위험물", align: "right", width: 100 },
];

export default function ItemListForFCL({
  containersInfo,
}: {
  containersInfo: ShipmentCreateContainersInfo[];
}) {
  const ItemGroupList = (() => {
    return containersInfo.map((containerInfo, i) => {
      // PO에서 의뢰생성 하는경우 item.name이 없고 item.products[]에 name이 있다.
      const itemName =
        containerInfo.name ??
        containerInfo.products?.map((v) => v.productName).join(",");

      return {
        item: {
          id: i + 1,
          name: itemName,
          cbm: 0,
          weight: 0,
          isDangerous: containerInfo.isDangerous,
        },
        containerInfo: containerInfo.containerList,
      };
    });
  })();

  const supply = containersInfo.reduce((acc, { containerList }) => {
    return (
      acc +
      containerList.reduce((acc, { containerType, quantity }) => {
        const FEU = containerType ? getFEUByContainerType(containerType) : 0;

        return acc + Number(quantity ?? 0) * FEU;
      }, 0)
    );
  }, 0);

  return (
    <Grid item xs={12}>
      <FCLItemList itemGroupList={ItemGroupList} supply={supply} />
    </Grid>
  );
}
