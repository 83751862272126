import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import { toThousandUnitFormat } from "@sellernote/shared/src/utils/common/number";
import { COLOR } from "@sellernote/shared/src/sds-v2/styles/colors";

function QuotationConfirm({
  bidId,
  quotationId,
  totalPrice,
  isImport,
  triggerSize = "medium",
  onBeforeUpdate,
  onAfterUpdate,
}: {
  bidId: number;
  quotationId: number;
  totalPrice: number;
  isImport: boolean;
  triggerSize?: "large" | "medium" | "small";
  onBeforeUpdate?: () => void;
  onAfterUpdate?: () => void;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const [opensModal, setOpensModal] = useState(false);

  const {
    mutate: confirmBidQuote,
    isLoading,
    ResponseHandler: ResponseHandlerOfConfirmBidQuote,
  } = ADMIN_BID_QUERY.useConfirmBidQuote();

  const {
    mutate: confirmExportBidQuote,
    isLoading: isExportLoading,
    ResponseHandler: ResponseHandlerOfConfirmExportBidQuote,
  } = ADMIN_BID_QUERY.useConfirmExportBidQuote();

  const handleQuotationConfirm = useCallback(async () => {
    await onBeforeUpdate?.();

    const confirmMutation = isImport ? confirmBidQuote : confirmExportBidQuote;

    confirmMutation(
      {
        pathParams: { bidId },
        bidId,
        quotationId,
      },
      {
        onSuccess() {
          handleSnackbarOpen("견적을 확정하였습니다.");
          setOpensModal(false);
          queryClient.invalidateQueries(
            ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({ bidId })
          );
          onAfterUpdate?.();
        },
      }
    );

    return;
  }, [
    bidId,
    confirmBidQuote,
    confirmExportBidQuote,
    handleSnackbarOpen,
    isImport,
    onAfterUpdate,
    onBeforeUpdate,
    queryClient,
    quotationId,
  ]);

  return (
    <>
      <Button
        variant="contained"
        size={triggerSize}
        color="success"
        onClick={() => setOpensModal(true)}
      >
        견적 확정하기
      </Button>

      <Dialog open={opensModal} onClose={() => setOpensModal(false)}>
        <DialogTitle>
          해당 초안 견적을 확정 견적으로 선택하시겠습니까?
        </DialogTitle>

        <DialogContent
          sx={{ display: "flex", flexDirection: "column", rowGap: "16px" }}
        >
          <Typography className="sads" color={COLOR.grayScale_800}>
            제출가: {toThousandUnitFormat(totalPrice)}원
          </Typography>

          <Box>
            <Typography component="div" className="sads" color="error">
              견적을 확정하기 전, 초안 견적란을 다시 한번 확인해주세요.
            </Typography>

            <Typography className="sads" color={COLOR.grayScale_800}>
              만약 수정이 필요할 경우, 견적서 초안의 “수정” 버튼을 클릭하여 수정
              완료 후 <br />
              “견적 확정하기” 버튼을 클릭하여 확정해주세요.
            </Typography>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            disabled={isLoading || isExportLoading}
            onClick={() => setOpensModal(false)}
          >
            아니오
          </Button>

          <Button
            variant="contained"
            disabled={isLoading || isExportLoading}
            onClick={handleQuotationConfirm}
          >
            예
          </Button>
        </DialogActions>
      </Dialog>

      {ResponseHandlerOfConfirmBidQuote}
      {ResponseHandlerOfConfirmExportBidQuote}
    </>
  );
}

export default QuotationConfirm;
