import { useCallback } from "react";

import { SEND_REQUEST_REQ_PRODUCTS_INFO_ITEM } from "@sellernote/shared/src/api-interfaces/shipda-api/quote";
import { FreightType } from "@sellernote/shared/src/types/common/common";
import {
  BidCreateFormData,
  CargoInfoFormType,
} from "@sellernote/shared/src/types/forwarding/adminBidCreate";
import {
  calculateCBM,
  calculateCW,
  calculateRTon,
  getCustomRound,
  toKg,
  toTon,
} from "@sellernote/shared/src/utils/common/number";

export default function useGetProductsInfoWithUnitSupply({
  freightType,
  cargoInfoFormType,
  createFormData,
}: {
  freightType: FreightType;
  cargoInfoFormType: CargoInfoFormType;
  createFormData: BidCreateFormData;
}) {
  const getUnitSupply = useCallback(
    (productsItem: SEND_REQUEST_REQ_PRODUCTS_INFO_ITEM) => {
      const calculatedCBM =
        calculateCBM({
          type: freightType as "air" | "lcl",
          width: productsItem.horizontal || 0,
          height: productsItem.height || 0,
          depth: productsItem.vertical || 0,
          sizeUnit: productsItem.volumeUnit,
        }) || 0;

      const cbm = calculatedCBM * productsItem.quantity || 0;

      if (freightType === "LCL") {
        if (cargoInfoFormType === "totalVolume") {
          return getCustomRound(
            calculateRTon(
              productsItem.cbm,
              toTon(productsItem.weight, productsItem.weightUnit)
            ),
            3
          );
        }
        return getCustomRound(
          calculateRTon(
            cbm ?? 0,
            toTon(productsItem.weight, productsItem.weightUnit)
          ),
          3
        );
      }

      // AIR는 소숫점 두자리로 계산
      if (cargoInfoFormType === "totalVolume" && productsItem) {
        return (
          getCustomRound(
            calculateCW({
              type: "cbm",
              cbm: productsItem.cbm,
              weight: toKg(productsItem.weight, productsItem.weightUnit),
            }),
            2
          ) ?? 0
        );
      }
      return (
        getCustomRound(
          calculateCW({
            type: "cbm",
            cbm: cbm ?? 0,
            weight: toTon(productsItem.weight ?? 0, productsItem.weightUnit),
          }),
          2
        ) ?? 0
      );
    },
    [cargoInfoFormType, freightType]
  );

  const getProductsInfoWithUnitSupply = useCallback(() => {
    return createFormData.productsInfo?.map((productsItem) => {
      if (cargoInfoFormType === "packaging") {
        return {
          ...productsItem,
          unitSupply: getUnitSupply(productsItem),
          cbm:
            getCustomRound(
              (calculateCBM({
                type: freightType as "air" | "lcl",
                width: productsItem.horizontal || 0,
                height: productsItem.height || 0,
                depth: productsItem.vertical || 0,
                sizeUnit: productsItem.volumeUnit,
              }) ?? 0) * productsItem.quantity,
              3
            ) ?? 0,
        };
      }
      return {
        ...productsItem,
        // packaging에서는 필요없는 정보
        volumeUnit: undefined,
        height: undefined,
        vertical: undefined,
        horizontal: undefined,
        unitSupply: getUnitSupply(productsItem),
      };
    });
  }, [
    cargoInfoFormType,
    createFormData.productsInfo,
    freightType,
    getUnitSupply,
  ]);

  return {
    getProductsInfoWithUnitSupply,
  };
}
