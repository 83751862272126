import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FactoryIcon from "@mui/icons-material/Factory";
import { Grid, Typography } from "@mui/material";

import {
  Incoterms,
  Port,
  WareHouse,
  Zone,
} from "@sellernote/shared/src/types/common/common";
import {
  BidServiceType,
  BidTransportType,
} from "@sellernote/shared/src/types/forwarding/bid";

import usePortStepData from "./usePortStepData";

export interface PortStepData {
  serviceType: BidServiceType;
  startAddress?: string;
  endCountry: string;
  wareHouse?: WareHouse;
  startCountry: string;
  startPort?: Port;
  endPort?: Port;
  startViaPort?: Port;
  endViaPort?: Port;
  endAddress?: string | null;
  endAddressDetail?: string | null;
  incoterms: Incoterms;
  zone: Zone | null;
  startAddressDetail?: string;
  isImport: boolean;
  startType: BidTransportType;
  endType: BidTransportType;
}

function PortStep({ portStepData }: { portStepData: PortStepData }) {
  const {
    endAddressName,
    startAddressName,
    endAddressDetailName,
    startAddressDetailName,
    startPortIcon,
    endPortIcon,
    startPortName,
    endPortName,
    getStartAndEndAddressColor,
  } = usePortStepData(portStepData);

  return (
    <Grid container columns={16} alignItems="baseline" sx={{ padding: "50px" }}>
      <Grid item container xs={3} direction="column" alignItems={"center"}>
        <Grid item>
          <FactoryIcon
            fontSize="large"
            color={getStartAndEndAddressColor("startAddress")}
          />
        </Grid>

        <Grid item>
          <Typography
            variant="body2"
            component="div"
            color={getStartAndEndAddressColor("startAddress")}
          >
            {startAddressName}
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            variant="body2"
            component="div"
            color={getStartAndEndAddressColor("startAddress")}
          >
            ({startAddressDetailName})
          </Typography>
        </Grid>
      </Grid>

      <Grid item container xs={1}>
        <ArrowForwardIosIcon
          color={
            portStepData.startAddress ||
            portStepData.serviceType === "consolidation"
              ? "primary"
              : "disabled"
          }
        />
      </Grid>

      <Grid item container xs={3} direction="column" alignItems={"center"}>
        <Grid item>{startPortIcon}</Grid>

        {startPortName}
      </Grid>

      <Grid item container xs={1}>
        <ArrowForwardIosIcon color="primary" />
      </Grid>

      <Grid item container xs={3} direction="column" alignItems={"center"}>
        <Grid item>{endPortIcon}</Grid>

        {endPortName}
      </Grid>

      <Grid item container xs={1}>
        <ArrowForwardIosIcon
          color={portStepData.endAddress ? "primary" : "disabled"}
        />
      </Grid>

      <Grid item container xs={3} direction="column" alignItems={"center"}>
        <Grid item>
          <FactoryIcon
            fontSize="large"
            color={getStartAndEndAddressColor("endAddress")}
          />
        </Grid>

        <Grid item>
          <Typography
            variant="body2"
            component="div"
            color={getStartAndEndAddressColor("endAddress")}
          >
            {endAddressName}
          </Typography>
        </Grid>

        <Grid item>
          <Typography
            variant="body2"
            component="div"
            color={getStartAndEndAddressColor("endAddress")}
          >
            ({endAddressDetailName})
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PortStep;
