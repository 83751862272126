import { createContext, ReactNode, useContext, useState } from "react";
import { AlertColor } from "@mui/material";

import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import { AdminBidDetail } from "@sellernote/shared/src/types/forwarding/adminBid";
import { Language } from "@sellernote/shared/src/types/forwarding/customs";
import { PartnerBusinessArea } from "@sellernote/shared/src/types/forwarding/partner";

interface BidPartnerContextType {
  partnerBusinessArea: PartnerBusinessArea;
  setPartnerBusinessArea: (value: PartnerBusinessArea) => void;
  bidAccountPayableId: number;
  setBidAccountPayableId: (value: number) => void;
  language: Language;
  setLanguage: (value: Language) => void;
  bidDetail: AdminBidDetail;
  partnerName: string;
  setPartnerName: (value: string) => void;
  showsRequestEmailToPartner: boolean;
  setShowsRequestEmailToPartner: (value: boolean) => void;
  showsMailCommentModal: boolean;
  setShowsMailCommentModal: (value: boolean) => void;
  handleSnackbarOpen: (message: string, color?: AlertColor) => void;
}

const BidPartnerContext = createContext<BidPartnerContextType | undefined>(
  undefined
);

export function useBidPartnerContext() {
  const context = useContext(BidPartnerContext);

  if (context === undefined) {
    throw new Error(
      "useBidPartnerContext must be used within a BidPartnerProvider"
    );
  }
  return context;
}

export function BidPartnerProvider({
  children,
  bidDetail,
}: {
  children: ReactNode;
  bidDetail: AdminBidDetail;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const [bidAccountPayableId, setBidAccountPayableId] = useState(0);
  const [partnerBusinessArea, setPartnerBusinessArea] =
    useState<PartnerBusinessArea>("domestic");
  const [language, setLanguage] = useState<Language>("en");
  const [partnerName, setPartnerName] = useState("");
  const [showsRequestEmailToPartner, setShowsRequestEmailToPartner] =
    useState(false);
  const [showsMailCommentModal, setShowsMailCommentModal] = useState(false);

  return (
    <BidPartnerContext.Provider
      value={{
        partnerBusinessArea,
        setPartnerBusinessArea,
        bidAccountPayableId,
        setBidAccountPayableId,
        language,
        setLanguage,
        bidDetail,
        partnerName,
        setPartnerName,
        showsRequestEmailToPartner,
        setShowsRequestEmailToPartner,
        showsMailCommentModal,
        setShowsMailCommentModal,
        handleSnackbarOpen,
      }}
    >
      {children}
    </BidPartnerContext.Provider>
  );
}
