import { useState } from "react";
import { Box, Button, Snackbar } from "@mui/material";
import { useAtomValue } from "jotai";

import { FORWARDING_ADMIN_AUTH_SELECTORS } from "@sellernote/shared/src/jotaiStates/auth";

export default function ButtonGroup({
  onCreateModalOpen,
}: {
  onCreateModalOpen: () => void;
}) {
  const [showsAlert, setShowsAlert] = useState(false);

  const auth = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const handleButtonClick = () => {
    /** 정산 담당자가 아니면 거래처 생성 불가능 */
    if (auth?.authority !== "finance") {
      setShowsAlert(true);
      return;
    }

    onCreateModalOpen();
  };

  return (
    <>
      <Box display={"flex"} gap={1}>
        <Button
          size="small"
          variant="contained"
          color="info"
          onClick={handleButtonClick}
        >
          거래처 등록
        </Button>

        <Button size="small" variant="contained" color="success">
          엑셀 다운로드
        </Button>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showsAlert}
        onClose={() => setShowsAlert(false)}
        message="정산 팀 이외에는 거래처 생성이 불가능합니다."
        color="warning"
      />
    </>
  );
}
