import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Box, Button, Checkbox, Chip, Typography } from "@mui/material";

import LegacyTable, {
  LegacyTableBodyRow,
  LegacyTableHeadCell,
} from "@sellernote/shared/src/admin-ui/components/LegacyTable";
import Modal from "@sellernote/shared/src/admin-ui/components/Modal";
import PartnerManagersListModal from "@sellernote/shared/src/admin-ui/containers/PartnerManagersListModal";
import { GET_ADMIN_BID_PARTNER_QUOTE_LIST_SEARCH_PARAMS } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminBid";
import useSearchWithTerm, {
  TermSearchType,
} from "@sellernote/shared/src/hooks/admin/useSearchWithTerm";
import useSet from "@sellernote/shared/src/hooks/common/useSet";
import ADMIN_BID_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import ADMIN_COMMON_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_COMMON_QUERY";
import {
  AdminBidDetail,
  AdminBidTransportMode,
} from "@sellernote/shared/src/types/forwarding/adminBid";
import {
  PartnerManagerItem,
  PartnerQuoteListItem,
} from "@sellernote/shared/src/types/forwarding/partner";
import { changeBooleanValueToKr } from "@sellernote/shared/src/utils/common/etc";
import { getMainManagerInfo } from "@sellernote/shared/src/utils/forwarding/admin/partner";

import { renderCountryInCharge } from "../../../../../../utils/partner";

import RequestQuoteToPartnerModal from "../../_components/RequestQuoteToPartnerModal";

type CellId =
  | keyof PartnerQuoteListItem
  | "mainManagerName"
  | "mainManagerPosition"
  | "mainManagerPhone"
  | "mainManagerEmail"
  | "showsManagersModal"
  | "checkbox";

function PartnerQuoteListModal({
  showsPartnerQuoteListModal,
  setShowsPartnerQuoteListModal,
  bidDetail,
  transportMode,
  handlePartnerQuoteListRefetch,
}: {
  showsPartnerQuoteListModal: boolean;
  setShowsPartnerQuoteListModal: Dispatch<SetStateAction<boolean>>;
  transportMode: AdminBidTransportMode;
  bidDetail: AdminBidDetail;
  handlePartnerQuoteListRefetch: () => void;
}) {
  const isImport = bidDetail.isImport;

  const [showsPartnerManagersListModal, setShowsPartnerManagersListModal] =
    useState(false);

  const [managers, setManagers] = useState<PartnerManagerItem[]>([]);

  const [showsRequestQuoteToPartnerModal, setShowsRequestQuoteToPartnerModal] =
    useState(false);

  const {
    array: partnerCheckBoxList,
    set: partnerCheckBoxSet,
    toggle: toggleCheckBox,
  } = useSet<number>();

  const {
    array: managerCheckBoxList,
    set: managerCheckBoxSet,
    toggle: toggleManagerCheckBox,
    init: initManagerCheckBoxSet,
  } = useSet<number>();

  const termSearchTypeOptions: TermSearchType<GET_ADMIN_BID_PARTNER_QUOTE_LIST_SEARCH_PARAMS>[] =
    [
      {
        label: "회사명",
        value: "companyName",
      },
      {
        label: "담당자명",
        value: "managerName",
      },
    ];

  const { debouncedSearchTerm, termSearchType, TermSearchPanel } =
    useSearchWithTerm({
      termSearchTypeOptions,
    });

  const { data: partnerQuoteList } =
    ADMIN_BID_QUERY.useGetAdminBidPartnerQuoteList({
      shipmentType: isImport ? "importation" : "exportation",
      bidId: bidDetail.id,
      country: isImport ? bidDetail.startCountry : bidDetail.endCountry,
      transportMode,
    });

  const { data: countryData = [] } = ADMIN_COMMON_QUERY.useGetCountryList();

  const getMainManager = useCallback((managers: PartnerManagerItem[]) => {
    const mainManager = managers.find((managerItem: PartnerManagerItem) => {
      return managerItem.isMain === true;
    });

    if (mainManager) {
      return mainManager.name;
    }
    return "";
  }, []);

  const getEmailList = useCallback(
    (managers: PartnerManagerItem[]) => {
      const managerEmails = managers
        .filter((managerItem) => {
          return managerCheckBoxSet.has(managerItem.id);
        })
        .map((managerItem: PartnerManagerItem) => {
          return managerItem.email;
        });
      return managerEmails;
    },
    [managerCheckBoxSet]
  );

  const receiverList = useMemo(() => {
    return partnerQuoteList?.list
      .filter((v) => {
        return partnerCheckBoxSet.has(v.id);
      })
      .map((v) => {
        return {
          language: v.language,
          mainManager: getMainManager(v.managers),
          needsPlainTextEmail: v.needsPlainTextEmail,
          name: v.name,
          emailList: getEmailList(v.managers),
          partnerCompany: v.id,
        };
      });
  }, [
    partnerCheckBoxSet,
    getEmailList,
    getMainManager,
    partnerQuoteList?.list,
  ]);

  const handlePartnerManagersListModalOpen = useCallback(
    (managers: PartnerManagerItem[]) => {
      return () => {
        setManagers(managers);
        setShowsPartnerManagersListModal(true);
      };
    },
    []
  );

  const handlePartnerCheckboxClick = useCallback(
    (partnerQuoteListItem: PartnerQuoteListItem) => {
      if (partnerCheckBoxSet.has(partnerQuoteListItem.id)) {
        const idsToCheck = partnerQuoteListItem.managers.map((managersItem) => {
          return managersItem.id;
        });

        const filterManagerCheckBoxList = managerCheckBoxList.filter((v) => {
          return !idsToCheck.includes(v);
        });

        initManagerCheckBoxSet(filterManagerCheckBoxList);
        toggleCheckBox(partnerQuoteListItem.id);
      } else {
        toggleCheckBox(partnerQuoteListItem.id);

        const idsToCheck = partnerQuoteListItem.managers.map((managersItem) => {
          return managersItem.id;
        });

        initManagerCheckBoxSet([...managerCheckBoxList, ...idsToCheck]);
      }
    },
    [
      partnerCheckBoxSet,
      initManagerCheckBoxSet,
      managerCheckBoxList,
      toggleCheckBox,
    ]
  );

  const headCells: LegacyTableHeadCell<CellId>[] = useMemo(() => {
    return [
      { id: "checkbox", disablePadding: false, label: "선택", width: 50 },
      {
        id: "requestQuoteFlag",
        disablePadding: false,
        label: "발송 유무",
        width: 100,
      },
      { id: "id", disablePadding: false, label: "ID", width: 80 },
      { id: "name", disablePadding: false, label: "회사명" },
      { id: "language", disablePadding: false, label: "언어", width: 70 },
      {
        id: "countries",
        disablePadding: false,
        label: "담당 국가",
        width: 140,
      },
      {
        id: "mainManagerName",
        disablePadding: false,
        label: "담당자 이름",
        width: 100,
      },
      {
        id: "mainManagerPosition",
        disablePadding: false,
        label: "직함",
        width: 100,
      },
      {
        id: "mainManagerPhone",
        disablePadding: false,
        label: "전화번호",
        width: 150,
      },
      {
        id: "mainManagerEmail",
        disablePadding: false,
        label: "이메일",
        width: 200,
      },
      {
        id: "showsManagersModal",
        disablePadding: false,
        label: "매니저",
        width: 100,
      },
    ];
  }, []);

  /**
   * 테이블 검색 시 API 요청을 하지 않고 프론트에서 직접 필터한다.
   *  - 견적 요청을 위한 payload receiverList를 만들어주기 위해 필터되지 않은 전체 리스트 데이터가 필요함
   */
  const rows = useMemo(() => {
    if (!partnerQuoteList?.list) return [];

    return partnerQuoteList.list
      .filter((partner) => {
        if (termSearchType?.value === "companyName") {
          const normalizedPartnerName = partner.name.toLocaleLowerCase();
          const normalizedSearchTerm = debouncedSearchTerm.toLocaleLowerCase();

          return normalizedPartnerName.includes(normalizedSearchTerm);
        }

        if (termSearchType?.value === "managerName") {
          return partner.managers.find((manager) => {
            const normalizedManagerName = manager.name.toLocaleLowerCase();
            const normalizedSearchTerm =
              debouncedSearchTerm.toLocaleLowerCase();

            return normalizedManagerName.includes(normalizedSearchTerm);
          });
        }

        return partner;
      })
      .map((partnerQuoteListItem) => {
        const row: LegacyTableBodyRow<CellId> = {
          checkbox: (
            <Checkbox
              checked={partnerCheckBoxSet.has(partnerQuoteListItem.id)}
              onClick={(e) => {
                e.stopPropagation();
                handlePartnerCheckboxClick(partnerQuoteListItem);
              }}
            />
          ),
          requestQuoteFlag: changeBooleanValueToKr(
            partnerQuoteListItem.requestQuoteFlag
          ),
          id: partnerQuoteListItem.id,
          name: partnerQuoteListItem.name,
          language: partnerQuoteListItem.language,
          countries: renderCountryInCharge({
            countryDataList: countryData,
            countryInChargeOfPartner: partnerQuoteListItem.countries,
          }),
          mainManagerName: getMainManagerInfo(
            "name",
            partnerQuoteListItem.managers
          ),
          mainManagerPosition: getMainManagerInfo(
            "position",
            partnerQuoteListItem.managers
          ),
          mainManagerPhone: getMainManagerInfo(
            "phone",
            partnerQuoteListItem.managers
          ),
          mainManagerEmail: getMainManagerInfo(
            "email",
            partnerQuoteListItem.managers
          ),
          showsManagersModal: (
            <Button
              onClick={handlePartnerManagersListModalOpen(
                partnerQuoteListItem.managers
              )}
            >
              보기
            </Button>
          ),
        };
        return row;
      });
  }, [
    partnerCheckBoxSet,
    countryData,
    debouncedSearchTerm,
    handlePartnerCheckboxClick,
    handlePartnerManagersListModalOpen,
    partnerQuoteList?.list,
    termSearchType?.value,
  ]);

  const handleModalClose = useCallback(() => {
    setShowsPartnerQuoteListModal(false);
  }, [setShowsPartnerQuoteListModal]);

  /** receiverList는 체크한 순서가 보장되지 않기 때문에 체크 순서를 보장하는 리스트를 추가 */
  const sortedPartnerList = (() => {
    return partnerCheckBoxList.map((partnerId) => {
      return {
        id: partnerId,
        name: receiverList?.find(
          (receiver) => receiver.partnerCompany === partnerId
        )?.name,
      };
    });
  })();

  const ModalBody = useMemo(() => {
    return (
      <>
        <LegacyTable
          toolbarItems={{
            left: [
              <Box key={"receiverList"}>
                <Typography variant="body2">{`총 ${
                  sortedPartnerList?.length || 0
                }개의 파트너가 선택되었습니다.`}</Typography>

                <Box
                  mt={1}
                  display={"flex"}
                  key={"receiverList"}
                  gap={1}
                  alignItems={"center"}
                  flexWrap={"wrap"}
                  width={900}
                >
                  <Typography variant="body2">선택한 파트너:</Typography>

                  {sortedPartnerList?.map((partner) => {
                    return (
                      <Chip
                        size="small"
                        key={partner.id}
                        label={partner.name}
                      />
                    );
                  })}
                </Box>
              </Box>,
            ],
            right: [
              TermSearchPanel,
              <Button
                key="request-button"
                variant="contained"
                onClick={() => setShowsRequestQuoteToPartnerModal(true)}
                disabled={partnerCheckBoxList.length === 0}
              >
                견적 요청
              </Button>,
            ],
          }}
          sx={{ height: "500px", width: "1440px" }}
          title="견적 파트너 리스트"
          headCells={headCells}
          rows={rows}
        />

        {showsPartnerManagersListModal && (
          <PartnerManagersListModal
            showsPartnerManagersListModal={showsPartnerManagersListModal}
            setShowsPartnerManagersListModal={setShowsPartnerManagersListModal}
            managers={managers}
            partnerCheckboxSet={partnerCheckBoxSet}
            managerCheckBoxSet={managerCheckBoxSet}
            toggleManagerCheckBox={toggleManagerCheckBox}
            initManagerCheckBoxSet={initManagerCheckBoxSet}
          />
        )}

        {showsRequestQuoteToPartnerModal && receiverList && bidDetail && (
          <RequestQuoteToPartnerModal
            isBidCopy={false}
            receiverList={receiverList}
            bidDetail={bidDetail}
            showsRequestQuoteModal={showsRequestQuoteToPartnerModal}
            setShowsRequestQuoteModal={setShowsRequestQuoteToPartnerModal}
            handlePartnerQuoteListRefetch={handlePartnerQuoteListRefetch}
          />
        )}
      </>
    );
  }, [
    sortedPartnerList,
    TermSearchPanel,
    partnerCheckBoxList.length,
    headCells,
    rows,
    showsPartnerManagersListModal,
    managers,
    partnerCheckBoxSet,
    managerCheckBoxSet,
    toggleManagerCheckBox,
    initManagerCheckBoxSet,
    showsRequestQuoteToPartnerModal,
    receiverList,
    bidDetail,
    handlePartnerQuoteListRefetch,
  ]);

  return (
    <Modal
      isOpened={showsPartnerQuoteListModal}
      handleClose={handleModalClose}
      modalBody={ModalBody}
    />
  );
}

export default PartnerQuoteListModal;
