import { useFormContext } from "react-hook-form";
import { Grid } from "@mui/material";

import { CHECK_IS_OCEANTICKET_RES } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminBidCreate";
import { BidCreateFormData } from "@sellernote/shared/src/types/forwarding/adminBidCreate";

import CheckPointItem from "./_components/CheckPointItem";

export default function LCLCheckPoint({
  isOceanticket,
}: {
  isOceanticket: CHECK_IS_OCEANTICKET_RES | undefined;
}) {
  const { watch } = useFormContext<BidCreateFormData>();

  const bidType = watch("bidType");

  return (
    <Grid item container direction={"column"} xs={11}>
      {bidType === "import" && (
        <>
          <CheckPointItem checkPoint="containLss" />

          <CheckPointItem checkPoint="containOceanSurcharge" />

          <CheckPointItem checkPoint="containDomesticFee" />

          {isOceanticket && (
            <CheckPointItem checkPoint="containsWarehouseFee" />
          )}
        </>
      )}
    </Grid>
  );
}
