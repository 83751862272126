import { useCallback, useState } from "react";
import {
  Box,
  Button,
  Divider,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";

import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import ADMIN_BID_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import { AdminBidDetail } from "@sellernote/shared/src/types/forwarding/adminBid";
import { getTransportMode } from "@sellernote/shared/src/utils/forwarding/admin/adminBid";

import PartnerQuoteListModal from "./PartnerQuoteListModal";
import QuotePartnerRadioList from "./QuotePartnerRadioList";

const boxLayoutStyle: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  gap: "4px",
};

function PartnerQuote({ bidDetail }: { bidDetail: AdminBidDetail }) {
  const { handleSnackbarOpen } = useSnackbar();

  const [showsPartnerQuoteListModal, setShowsPartnerQuoteListModal] =
    useState(false);

  const { data: partnerQuote, refetch: refetchPartnerQuote } =
    ADMIN_BID_QUERY.useGetAdminBidPartnerQuote({
      bidId: bidDetail.id,
    });

  const {
    mutate: resetPartnerQuote,
    ResponseHandler: ResponseHandlerOfResetPartnerQuote,
  } = ADMIN_BID_QUERY.useResetPartnerQuote(bidDetail.id);

  const handlePartnerQuoteListRefetch = () => {
    refetchPartnerQuote();
  };

  const partnerName = (() => {
    if (!partnerQuote?.list || partnerQuote.list.length < 1) {
      return <Typography>견적 파트너 없음</Typography>;
    }

    return (
      <QuotePartnerRadioList
        partnerList={partnerQuote.list}
        shipmentId={bidDetail.id}
        onPartnerQuoteListRefetch={handlePartnerQuoteListRefetch}
        bidStatus={bidDetail.status}
      />
    );
  })();

  const bidTransportMode = getTransportMode(
    bidDetail.freightType,
    bidDetail.serviceType
  );

  const handlePartnerQuoteListModalOpen = () => {
    setShowsPartnerQuoteListModal(true);
  };

  const handlePartnerQuoteReset = useCallback(() => {
    resetPartnerQuote(bidDetail.id, {
      onSuccess: () => {
        handleSnackbarOpen("견적 파트너를 초기화 했습니다.");
        refetchPartnerQuote();
      },

      onError: () => {
        handleSnackbarOpen("견적 파트너 초기화에 실패했습니다.", "error");
      },
    });
  }, [
    bidDetail.id,
    handleSnackbarOpen,
    refetchPartnerQuote,
    resetPartnerQuote,
  ]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Typography variant="h6">파트너 견적 문의</Typography>

      <Box
        sx={{
          display: "flex",
          gap: 4,
        }}
      >
        <Box sx={boxLayoutStyle}>
          <Typography>파트너:</Typography>

          <Button
            variant="contained"
            onClick={handlePartnerQuoteListModalOpen}
            disabled={
              bidDetail.status === "inProgress" ||
              bidDetail.status === "finished"
            }
          >
            선택
          </Button>
        </Box>

        <Divider orientation="vertical" variant="middle" flexItem />

        <Box sx={boxLayoutStyle}>
          <Typography>견적 파트너:</Typography>

          <Box>{partnerName}</Box>
        </Box>

        <Divider orientation="vertical" variant="middle" flexItem />

        <Box sx={boxLayoutStyle}>
          <Typography>문의 초기화:</Typography>

          <Button
            variant="contained"
            color="error"
            onClick={handlePartnerQuoteReset}
            disabled={
              bidDetail.status === "inProgress" ||
              bidDetail.status === "finished"
            }
          >
            초기화
          </Button>
        </Box>
      </Box>

      {showsPartnerQuoteListModal && (
        <PartnerQuoteListModal
          transportMode={bidTransportMode}
          showsPartnerQuoteListModal={showsPartnerQuoteListModal}
          setShowsPartnerQuoteListModal={setShowsPartnerQuoteListModal}
          bidDetail={bidDetail}
          handlePartnerQuoteListRefetch={handlePartnerQuoteListRefetch}
        />
      )}

      {ResponseHandlerOfResetPartnerQuote}
    </Box>
  );
}

export default PartnerQuote;
