import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Button, Grid, Typography } from "@mui/material";

import SADSTable, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/shared/src/admin-ui/components/SADSTable";
import TextFieldWithReactHookForm from "@sellernote/shared/src/admin-ui/components/TextFieldWithReactHookForm";
import { REQUEST_PARTNER_QUOTE_FOR_IMPORT_REQ } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminBid";
import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import ADMIN_BID_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import {
  AdminBidDetail,
  AdminBidPartnerQuoteProductInfo,
  AdminBidPartnerQuoteReceiverList,
} from "@sellernote/shared/src/types/forwarding/adminBid";

type CellKey = "품명" | "품명(영문)";

const headCellList: TableHeadCell<CellKey>[] = [
  { cellKey: "품명", label: "품명", width: 300 },
  { cellKey: "품명(영문)", label: "품명(영문)", width: 300 },
];

function ProductsInfoNameForm({
  bidDetail,
  receiverList,
  handlePartnerQuoteListRefetch,
  needItemNameEn,
  isBidCopy,
  handleBidCopyModalClose,
  copiedBidId,
  refetchShipmentDetail,
}: {
  bidDetail: AdminBidDetail;
  receiverList: AdminBidPartnerQuoteReceiverList[];
  handlePartnerQuoteListRefetch?: () => void;
  needItemNameEn: boolean;
  isBidCopy: boolean;
  handleBidCopyModalClose?: () => void;
  copiedBidId?: number;
  refetchShipmentDetail: () => void;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    generalFreightInfo: AdminBidPartnerQuoteProductInfo[];
    comment: REQUEST_PARTNER_QUOTE_FOR_IMPORT_REQ["comment"];
  }>({
    defaultValues: {
      generalFreightInfo: bidDetail.itemGroupList.map((v) => {
        const result: AdminBidPartnerQuoteProductInfo = {
          item: {
            itemName: v.item.name,
            itemNameEn: v.item.engName || "",
            isDangerous: v.item.isDangerous,
            isDouble: v.item.canStack,
            unitSupply: v.item.unitSupply,
            volumeUnit: v.item.volumeUnit,
            weightUnit: v.item.weightUnit,
            weight: v.item.weight,
            packingType: v.item.packingType,
            vertical: v.item.vertical,
            horizontal: v.item.horizontal,
            height: v.item.height,
            cbm: v.item.cbm,
            quantity: v.item.quantity,
          },
        };

        return result;
      }),
      comment: "",
    },
  });

  const {
    mutate: requestPartnerQuoteForImport,
    ResponseHandler: ResponseHandlerOfRequestPartnerQuoteForImport,
  } = ADMIN_BID_QUERY.useRequestPartnerQuoteForImport(bidDetail.id);

  const {
    mutate: requestPartnerQuoteForExport,
    ResponseHandler: ResponseHandlerOfRequestPartnerQuoteForExport,
  } = ADMIN_BID_QUERY.useRequestPartnerQuoteForExport(bidDetail.id);

  const requestPartnerQuote = bidDetail.isImport
    ? requestPartnerQuoteForImport
    : requestPartnerQuoteForExport;

  const ResponseHandlerOfRequestPartnerQuote = bidDetail.isImport
    ? ResponseHandlerOfRequestPartnerQuoteForImport
    : ResponseHandlerOfRequestPartnerQuoteForExport;

  const {
    mutate: copyPartnerQuote,
    ResponseHandler: ResponseHandlerOfCopyPartnerQuote,
  } = ADMIN_BID_QUERY.useCopyPartnerQuote();

  const handlePartnerQuoteRequest = () => {
    if (isBidCopy) {
      copyPartnerQuote(
        {
          comment: watch("comment"),
          generalFreightInfo: watch("generalFreightInfo"),
          bidId: isBidCopy && copiedBidId ? copiedBidId : bidDetail.id,
        },
        {
          onSuccess: () => {
            handleBidCopyModalClose && handleBidCopyModalClose();
          },

          onError: () => {
            handleSnackbarOpen("요청에 실패했습니다.", "error");
          },
        }
      );
      return;
    }

    requestPartnerQuote(
      {
        comment: watch("comment"),
        generalFreightInfo: watch("generalFreightInfo"),
        bidId: bidDetail.id,
        receiverList,
      },
      {
        onSuccess: () => {
          handleSnackbarOpen("요청에 성공했습니다.");
          handlePartnerQuoteListRefetch && handlePartnerQuoteListRefetch();
          refetchShipmentDetail();
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  const bodyRowList: TableBodyRow<CellKey>[] = (
    bidDetail.itemGroupList || []
  ).map(({ item }, index) => {
    return {
      rowKey: item.id,
      품명: {
        value: (
          <Grid container>
            <TextFieldWithReactHookForm
              name={`generalFreightInfo.${index}.item.itemName`}
              control={control}
              error={
                errors.generalFreightInfo &&
                errors.generalFreightInfo[index]?.item?.itemName?.type ===
                  "required"
              }
              helperText={
                errors.generalFreightInfo &&
                errors.generalFreightInfo[index]?.item?.itemName?.type ===
                  "required" &&
                "필수 입력입니다."
              }
            />
          </Grid>
        ),
      },
      "품명(영문)": {
        value: (
          <Grid container>
            <TextFieldWithReactHookForm
              name={`generalFreightInfo.${index}.item.itemNameEn`}
              fullWidth
              control={control}
              required={needItemNameEn}
              error={
                errors.generalFreightInfo &&
                errors.generalFreightInfo[index]?.item?.itemNameEn?.type ===
                  "required"
              }
              helperText={
                errors.generalFreightInfo &&
                errors.generalFreightInfo[index]?.item?.itemNameEn?.type ===
                  "required" &&
                "필수 입력입니다."
              }
            />
          </Grid>
        ),
      },
    };
  });

  return (
    <Grid
      container
      spacing={4}
      direction="row"
      component="form"
      onSubmit={handleSubmit(handlePartnerQuoteRequest)}
    >
      <Grid item>
        <Typography variant="h5" component="div">
          운임문의 요청
        </Typography>
      </Grid>

      <Grid item width={"100%"}>
        <Typography variant="h6" component="div">
          화물
        </Typography>

        <SADSTable headCellList={headCellList} bodyRowList={bodyRowList} />
      </Grid>

      <Grid item width={"100%"}>
        <Typography variant="h6" component="div">
          코멘트
        </Typography>

        <TextFieldWithReactHookForm
          name={"comment"}
          control={control}
          fullWidth={true}
        />
      </Grid>

      <Grid item container justifyContent={"end"}>
        <Grid item>
          <Button variant="contained" type="submit">
            운임문의 요청
          </Button>
        </Grid>

        {isBidCopy && handleBidCopyModalClose && (
          <Grid item>
            <Button
              variant="outlined"
              type="submit"
              onClick={handleBidCopyModalClose}
            >
              돌아가기
            </Button>
          </Grid>
        )}
      </Grid>

      {ResponseHandlerOfRequestPartnerQuote}
      {ResponseHandlerOfCopyPartnerQuote}
    </Grid>
  );
}

export default ProductsInfoNameForm;
