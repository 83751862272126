import { useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Grid, Skeleton, Typography } from "@mui/material";

import { CHECK_IS_OCEANTICKET_RES } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminBidCreate";
import { BidCreateFormData } from "@sellernote/shared/src/types/forwarding/adminBidCreate";

import AIRCheckPoint from "./AIRCheckPoint";
import FCLCheckPoint from "./FCLCheckPoint";
import LCLCheckPoint from "./LCLCheckPoint";

function CheckPoints({
  isOceanticket,
}: {
  isOceanticket: CHECK_IS_OCEANTICKET_RES | undefined;
}) {
  const { watch, setValue } = useFormContext<BidCreateFormData>();

  const freightType = watch("freightType");
  const bidType = watch("bidType");
  const startType = watch("startType");
  const incoterms = watch("incoterms");

  const [prevFreightType, setPrevFreightType] = useState(freightType);
  const [prevBidType, setPrevBidType] = useState(bidType);
  const [prevIncoterms, setPrevIncoterms] = useState(incoterms);

  /**
   * dom 마운트호 체크포인트를 기본값으로 초기화하는 callback ref
   */
  const initDefaultCheckPoints = useCallback(
    (node) => {
      if (node !== null) {
        if (
          freightType !== prevFreightType ||
          bidType !== prevBidType ||
          incoterms !== prevIncoterms
        ) {
          setPrevFreightType(freightType);
          setPrevBidType(bidType);
          setPrevIncoterms(incoterms);

          const hasVisitedQuotationPage = sessionStorage.getItem(
            "hasVisitedQuotationPage"
          );

          /** 견적서 작성 페이지에서 돌아오는 경우 첫 렌더링에서 체크포인트를 자동셋팅하지 않기 위해 추가   */
          if (hasVisitedQuotationPage === "TRUE") {
            /** 값이 계속 남아있으면 폼 입력에 따른 체크포인트 셋팅이 되지 않기 때문에 제거 */
            sessionStorage.removeItem("hasVisitedQuotationPage");
            return;
          }

          /** 수출 의뢰 체크포인트 값 셋팅 */
          if (bidType === "export") {
            const isFclAndStartTypeInland =
              freightType === "FCL" && startType === "inland";

            if (isFclAndStartTypeInland) {
              setValue("containerStuffing", "FALSE");
            }
            return;
          }

          /** 수입 의뢰 체크포인트 값 셋팅 */
          if (freightType === "FCL") {
            setValue("containLss", "FALSE");
            setValue("containOceanSurcharge", "TRUE");
            setValue("containDomesticFee", "TRUE");
            setValue("containerAccessable", "TRUE");
            return;
          }

          if (freightType === "LCL") {
            setValue("containLss", "FALSE");
            setValue("containOceanSurcharge", "TRUE");
            setValue("containDomesticFee", "TRUE");
            setValue("containsWarehouseFee", "TRUE");

            return;
          }

          if (freightType === "AIR") {
            setValue("containDomesticFee", "TRUE");
            return;
          }
        }
      }
    },
    [
      bidType,
      freightType,
      incoterms,
      prevBidType,
      prevFreightType,
      prevIncoterms,
      setValue,
      startType,
    ]
  );

  return (
    <Grid
      ref={initDefaultCheckPoints}
      item
      container
      alignItems="flex-start"
      spacing={3}
    >
      <Grid item xs={1}>
        <Typography variant="subtitle1" component="div">
          체크포인트
        </Typography>
      </Grid>

      {/* 체크포인트 조건 변경 시 제대로 재렌더링이 되지 않아 추가 */}
      {freightType !== prevFreightType ||
      bidType !== prevBidType ||
      incoterms !== prevIncoterms ? (
        <Skeleton height={200} />
      ) : (
        <>
          {freightType === "FCL" && <FCLCheckPoint />}

          {freightType === "LCL" && (
            <LCLCheckPoint isOceanticket={isOceanticket} />
          )}

          {freightType === "AIR" && <AIRCheckPoint />}
        </>
      )}
    </Grid>
  );
}

export default CheckPoints;
