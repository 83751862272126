import { useCallback, useState } from "react";
import DaumPostcodeEmbed from "react-daum-postcode";
import { useFormContext } from "react-hook-form";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";

import AutoCompleteWithReactHookForm from "@sellernote/shared/src/admin-ui/components/AutoCompleteWithReactHookForm";
import RadioGroupWithReactHookForm, {
  RadioGroupWithReactHookFormOption,
} from "@sellernote/shared/src/admin-ui/components/RadioGroupWithReactHookForm";
import TextFieldWithReactHookForm from "@sellernote/shared/src/admin-ui/components/TextFieldWithReactHookForm";
import { inlandTransportTypeRadioGroupOptionList } from "@sellernote/shared/src/admin-ui/pageContainers/bid/constants";
import { GET_ADMIN_ZONE_ADDRESS_RES } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminZoneAddress";
import { BidCreateFormData } from "@sellernote/shared/src/types/forwarding/adminBidCreate";
import { BidTransportType } from "@sellernote/shared/src/types/forwarding/bid";

import { handleBofulAddressCheck } from "../utils";

import useSyncZoneId from "./_hooks/useSyncZoneId";

function Export({
  startAddress,
  zoneAddress,
  getPortNameListOptions,
  startTypeRadioGroupOption,
  endTypeRadioGroupOption,
}: {
  startAddress: string | undefined;
  zoneAddress: GET_ADMIN_ZONE_ADDRESS_RES | undefined;
  getPortNameListOptions: (type: "startType" | "endType") => string[];
  startTypeRadioGroupOption: RadioGroupWithReactHookFormOption<BidTransportType>[];
  endTypeRadioGroupOption: RadioGroupWithReactHookFormOption<BidTransportType>[];
}) {
  const { control, watch, setValue, clearErrors } =
    useFormContext<BidCreateFormData>();

  const [isBofulAddress, setIsBofulAddress] = useState(false);
  const [prevStartAddress, setPrevStartAddress] = useState(startAddress);
  const [opensDaumPostcode, setOpensDaumPostcode] = useState(false);

  const { startType, endType, zoneId, freightType } = watch();

  const { syncZoneId } = useSyncZoneId({
    address: startAddress,
    prevAddress: prevStartAddress,
    setPrevAddress: setPrevStartAddress,
    zoneId,
    zoneAddress,
    setIsBofulAddress,
  });

  /**
   * TransportSection 관련 상태를 초기화
   */
  const initTransportSection = useCallback(
    (node) => {
      if (node !== null) {
        syncZoneId();
      }
    },
    [syncZoneId]
  );

  return (
    <Grid
      ref={initTransportSection}
      item
      container
      spacing={3}
      alignItems="base-line"
      xs={12}
    >
      <Grid item container spacing={3}>
        <Grid item xs={1}>
          <Typography variant="subtitle1" component="span">
            출발지
          </Typography>
        </Grid>

        <Grid item container xs={5} direction="column" spacing={1}>
          <Grid item>
            <RadioGroupWithReactHookForm
              name="startType"
              control={control}
              defaultValue="sea"
              radioGroupOption={startTypeRadioGroupOption}
              handleEffectOnChange={(e) => {
                // 출발지 타입을 변경하는 경우 입력한 출발지 정보를 제거
                if (e.target.value === "sea" || e.target.value === "air") {
                  setValue("zoneId", undefined);
                  setValue("startAddress", undefined);
                  setValue("startAddressDetail", undefined);
                  setValue("startViaPort", undefined);
                  setIsBofulAddress(false);
                  return;
                }

                // 반대인 경우 출발지 항구를 제거
                setValue("startPort", undefined);
              }}
            />
          </Grid>

          {startType === "inland" ? (
            <>
              <Grid item container>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: "8px",
                    }}
                  >
                    <TextFieldWithReactHookForm
                      name="startAddress"
                      control={control}
                      label="출발지 주소"
                      placeholder="출발지 주소"
                      required={true}
                      InputProps={{
                        readOnly: true,
                        endAdornment: startAddress && (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => {
                                setValue("startAddress", "", {
                                  shouldDirty: true,
                                });
                              }}
                            >
                              <CloseIcon sx={{ fontSize: 20 }} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={{ flexGrow: 1 }}
                    />

                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => setOpensDaumPostcode(!opensDaumPostcode)}
                    >
                      주소검색 {opensDaumPostcode && "닫기"}
                    </Button>
                  </Box>

                  {opensDaumPostcode && (
                    <DaumPostcodeEmbed
                      showMoreHName={true}
                      onComplete={({ address }) => {
                        setValue("startAddress", address, {
                          shouldDirty: true,
                        });
                        clearErrors("startAddress");
                        setOpensDaumPostcode(false);
                      }}
                    />
                  )}
                </Grid>
              </Grid>

              <Grid item>
                <TextFieldWithReactHookForm
                  name="startAddressDetail"
                  control={control}
                  label="출발지 상세주소"
                  fullWidth
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isBofulAddress}
                      onClick={() =>
                        handleBofulAddressCheck({
                          isBofulAddress,
                          setIsBofulAddress,
                          setValue,
                          isImport: true,
                        })
                      }
                    />
                  }
                  label="풀필먼트 센터 이용"
                />
              </Grid>

              <Grid item>
                <AutoCompleteWithReactHookForm
                  name="startViaPort"
                  control={control}
                  label="항구/공항 선택"
                  options={getPortNameListOptions("startType")}
                />
              </Grid>

              {freightType !== "FCL" && (
                <Grid item>
                  <RadioGroupWithReactHookForm
                    name="inlandTransportType"
                    control={control}
                    defaultValue="sole"
                    radioGroupOption={inlandTransportTypeRadioGroupOptionList}
                  />
                </Grid>
              )}
            </>
          ) : (
            <Grid item>
              <AutoCompleteWithReactHookForm
                name="startPort"
                control={control}
                label="항구/공항 선택"
                options={getPortNameListOptions("startType")}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item container spacing={3}>
        <Grid item xs={1}>
          <Typography variant="subtitle1" component="span">
            도착지
          </Typography>
        </Grid>

        <Grid item container xs={5} direction="column">
          <Grid item>
            <RadioGroupWithReactHookForm
              name="endType"
              control={control}
              defaultValue="sea"
              radioGroupOption={endTypeRadioGroupOption}
              handleEffectOnChange={(e) => {
                // 도착지 타입을 변경하는 경우 입력한 도착지 정보를 제거
                if (e.target.value === "sea" || e.target.value === "air") {
                  setValue("endAddress", undefined);
                  setValue("endViaPort", undefined);
                  return;
                }
                // 반대인 경우 도착지 항구를 제거
                setValue("endPort", undefined);
              }}
            />
          </Grid>

          {endType === "inland" ? (
            <>
              <Grid item>
                <AutoCompleteWithReactHookForm
                  name="endViaPort"
                  control={control}
                  label="항구/공항 선택"
                  options={getPortNameListOptions("endType")}
                />
              </Grid>

              <Grid item>
                <TextFieldWithReactHookForm
                  name="endAddress"
                  control={control}
                  label="도착지 주소"
                  fullWidth
                />
              </Grid>
            </>
          ) : (
            <Grid item>
              <AutoCompleteWithReactHookForm
                name="endPort"
                control={control}
                label="항구/공항 선택"
                // portList에서 같은 이름으로 중복된 항구/공항을 제거
                options={getPortNameListOptions("endType")}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Export;
