import { useMemo } from "react";
import { Chip, Grid } from "@mui/material";

import CargoInfo from "@sellernote/shared/src/admin-ui/components/CargoInfo";
import { AdminBidDetail } from "@sellernote/shared/src/types/forwarding/adminBid";
import { ExchangeRate } from "@sellernote/shared/src/types/forwarding/trello";

import CheckPoint from "../_components/CheckPoint";
import ExchangeRateInfo from "../_components/ExchangeRateInfo";
import ExportCheckPointAndAddition from "../_components/ExportCheckPointAndAddition";
import PortStep from "../_components/PortStep";
import SummaryHeader from "../_components/SummaryHeader";

export default function ItemInfoForRead({
  bidDetail,
  exchangeRateList,
}: {
  bidDetail: AdminBidDetail;
  exchangeRateList: ExchangeRate[];
}) {
  const portStepData = useMemo(() => {
    return {
      serviceType: bidDetail.serviceType,
      startAddress: bidDetail.startAddress,
      endCountry: bidDetail.endCountry,
      wareHouse: bidDetail.wareHouse,
      startCountry: bidDetail.startCountry,
      startPort: bidDetail.startPort,
      endPort: bidDetail.endPort,
      startViaPort: bidDetail.startViaPort,
      endViaPort: bidDetail.endViaPort,
      endAddress: bidDetail.endAddress,
      endAddressDetail: bidDetail.endAddressDetail,
      incoterms: bidDetail.incoterms,
      zone: bidDetail.zone,
      startAddressDetail: bidDetail.startAddressDetail,
      isImport: bidDetail.isImport,
      startType: bidDetail.startType,
      endType: bidDetail.endType,
    };
  }, [bidDetail]);

  return (
    <Grid container spacing={3}>
      <Grid item container xs={12}>
        <SummaryHeader
          freightType={bidDetail.freightType}
          id={bidDetail.id}
          supply={bidDetail.supply}
        />
      </Grid>

      <Grid item container xs={12}>
        {portStepData && <PortStep portStepData={portStepData}></PortStep>}
      </Grid>

      <Grid item container xs={12} padding={6}>
        <Grid container spacing={2} sx={{ padding: "50px" }}>
          <Grid item xs={12}>
            <Chip
              color="primary"
              label="컨테이너 상세정보"
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <CargoInfo bidDetail={bidDetail} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item container xs={12}>
        {/* 수출 의뢰데이터 있을 때 체크&부가서비스 항목 */}
        {!bidDetail.isImport && (
          <ExportCheckPointAndAddition
            type="read"
            containerAccessable={bidDetail.containerAccessable}
            containerStuffing={bidDetail.containerStuffing}
            originInland={bidDetail.originInland}
            useVGM={bidDetail.useVGM}
            containCustoms={bidDetail.containCustoms}
            containExportCustoms={bidDetail.containExportCustoms}
            hopeCargoInsurance={bidDetail.hopeCargoInsurance}
            freightType={bidDetail.freightType}
          />
        )}

        {/* 수입 의뢰데이터 있을 때 체크 및 부가서비스 항목 */}
        {bidDetail.isImport && (
          <CheckPoint
            containDomesticFee={bidDetail.containDomesticFee}
            containOceanSurcharge={bidDetail.containOceanSurcharge}
            containLss={bidDetail.containLss}
            containerAccessable={bidDetail.containerAccessable}
            hopeCargoInsurance={bidDetail.hopeCargoInsurance}
            freightType={bidDetail.freightType}
            locale={bidDetail.locale}
            needIORAgency={bidDetail.needIORAgency}
            needFTACertificateAgency={bidDetail.needFTACertificateAgency}
          />
        )}
      </Grid>

      <Grid item container xs={12}>
        <ExchangeRateInfo exchangeRateList={exchangeRateList} />
      </Grid>
    </Grid>
  );
}
