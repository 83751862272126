import { useParams } from "react-router-dom";

import Layout from "@sellernote/shared/src/admin-ui/containers/Layout";
import Loading from "@sellernote/shared/src/componentsToMoveToV1/Loading";

import ItemInfoForRead from "pages/bid/_components/ItemInfo/ItemInfoForRead";

import DraftQuotation from "../_components/DraftQuotation";
import FixedQuotation from "../_components/FixedQuotation";
import useQuotationData from "../_hooks/useQuotationData";

const BidQuotationEdit = () => {
  const {
    id,
    quotationType,
  }: { id: string; quotationType: "fixed" | "draft" } = useParams();

  const {
    bidDetail,
    exchangeRateList,
    applyItems,
    linerList,
    isShipmentDetailFetching,
  } = useQuotationData({ bidId: Number(id) });

  const isFixed = quotationType === "fixed";

  const isLoading =
    !bidDetail ||
    !exchangeRateList ||
    !applyItems ||
    !linerList ||
    isShipmentDetailFetching;

  const QuotationComponent = isFixed ? FixedQuotation : DraftQuotation;

  return (
    <Layout title={`견적서 ${isFixed ? "확정" : "초안"}`}>
      {isLoading ? (
        <Loading active={true} />
      ) : (
        <>
          <ItemInfoForRead
            bidDetail={bidDetail}
            exchangeRateList={exchangeRateList}
          />
          <QuotationComponent
            bidDetail={bidDetail}
            exchangeRateList={exchangeRateList}
            applyItems={applyItems}
            linerList={linerList}
          />
        </>
      )}
    </Layout>
  );
};

export default BidQuotationEdit;
