import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { Button, Checkbox } from "@mui/material";

import LegacyTable, {
  LegacyTableBodyRow,
  LegacyTableHeadCell,
} from "@sellernote/shared/src/admin-ui/components/LegacyTable";
import Modal from "@sellernote/shared/src/admin-ui/components/Modal";
import PartnerManagersListModal from "@sellernote/shared/src/admin-ui/containers/PartnerManagersListModal";
import useGetObjectWithTermSearchTypeKey from "@sellernote/shared/src/hooks/admin/useGetObjectWithTermSearchTypeKey";
import useSearchWithTerm, {
  TermSearchType,
} from "@sellernote/shared/src/hooks/admin/useSearchWithTerm";
import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import useSet from "@sellernote/shared/src/hooks/common/useSet";
import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import ADMIN_COMMON_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_COMMON_QUERY";
import TRELLO_BID_QUERY from "@sellernote/shared/src/queries/forwarding/admin/TRELLO_BID_QUERY";
import PARTNER_QUERY from "@sellernote/shared/src/queries/forwarding/PARTNER_QUERY";
import { SellernoteAppRegion } from "@sellernote/shared/src/types/common/common";
import {
  PartnerBusinessArea,
  PartnerListItem,
  PartnerManagerItem,
} from "@sellernote/shared/src/types/forwarding/partner";
import { getMainManagerInfo } from "@sellernote/shared/src/utils/forwarding/admin/partner";

import { renderCountryInCharge } from "../../../../../../../utils/partner";

import { useBidPartnerContext } from "../../../_hooks/useBidPartnerContext";

type CellId =
  | keyof PartnerListItem
  | "mainManagerName"
  | "mainManagerPosition"
  | "mainManagerPhone"
  | "mainManagerEmail"
  | "showsManagersModal"
  | "checkbox";

const termSearchTypeOptions: TermSearchType<"companyName">[] = [
  {
    label: "회사명",
    value: "companyName",
  },
];

function BidPartnerListModal({
  showsBidPartnerModal,
  setShowsBidPartnerModal,
  transportMode,
  region,
}: {
  showsBidPartnerModal: boolean;
  setShowsBidPartnerModal: React.Dispatch<React.SetStateAction<boolean>>;
  transportMode: "FCL" | "LCL" | "AIR" | "EXPRESS";
  region: SellernoteAppRegion;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const { partnerBusinessArea, bidAccountPayableId, bidDetail } =
    useBidPartnerContext();

  const queryClient = useQueryClient();

  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [showsPartnerManagersListModal, setShowsPartnerManagersListModal] =
    useState(false);
  const [managers, setManagers] = useState<PartnerManagerItem[]>([]);

  const {
    mutate: updateAccountPayable,
    ResponseHandler: ResponseHandlerOfUpdateAccountPayable,
  } = TRELLO_BID_QUERY.useUpdateAccountPayable(bidDetail.id);

  const {
    mutate: updateBidPartner,
    ResponseHandler: ResponseHandlerOfUpdateBidPartner,
  } = ADMIN_BID_QUERY.useUpdateBidPartner(bidDetail.id);

  const { set: checkBoxSet, init: initCheckBoxSet } = useSet<number>();

  const {
    array: managerCheckBoxArr,
    set: managerCheckBoxSet,
    toggle: toggleManagerCheckBox,
    init: initManagerCheckBoxSet,
  } = useSet<number>();

  const { debouncedSearchTerm, termSearchType, TermSearchPanel } =
    useSearchWithTerm({
      termSearchTypeOptions,
    });

  const { objectWithTermSearchTypeKey } = useGetObjectWithTermSearchTypeKey({
    termSearchType,
    debouncedSearchTerm,
  });

  const { data: countryData = [] } = ADMIN_COMMON_QUERY.useGetCountryList();

  const handleBidPartnerUpdate = useCallback(
    ({
      partnerBusinessArea,
      bidAccountPayableId,
      partnerManagersIds,
    }: {
      partnerBusinessArea: PartnerBusinessArea;
      bidAccountPayableId: number;
      partnerManagersIds: number[];
    }) => {
      updateBidPartner(
        {
          businessArea: partnerBusinessArea,
          bidAccountPayableId,
          partnerManagersIds,
        },
        {
          onSuccess: () => {
            handleSnackbarOpen("요청에 성공했습니다.");
            queryClient.invalidateQueries(
              ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
                bidId: bidDetail.id,
              })
            );
          },

          onError: ({ response }) => {
            if (response?.data?.code === 400) {
              handleSnackbarOpen(
                bidDetail.isImport
                  ? "수출자 정보를 먼저 입력해야 합니다."
                  : "수입자 정보를 먼저 입력해야 합니다.",
                "error"
              );
              return;
            } else {
              handleSnackbarOpen("요청에 실패했습니다.", "error");
            }
          },
        }
      );
    },
    [
      bidDetail.id,
      bidDetail.isImport,
      handleSnackbarOpen,
      queryClient,
      updateBidPartner,
    ]
  );

  const handleAccountPayableUpdate = useCallback(() => {
    if (bidAccountPayableId) {
      handleBidPartnerUpdate({
        partnerBusinessArea,
        bidAccountPayableId,
        partnerManagersIds: managerCheckBoxArr,
      });
    } else {
      updateAccountPayable(
        { domain: partnerBusinessArea },
        {
          onSuccess: ({ data }) => {
            handleBidPartnerUpdate({
              partnerBusinessArea,
              bidAccountPayableId: data.bidAccountPayableId,
              partnerManagersIds: managerCheckBoxArr,
            });
          },

          onError: ({ response }) => {
            handleSnackbarOpen("AccountPayable 생성에 실패했습니다.", "error");
          },
        }
      );
    }
  }, [
    bidAccountPayableId,
    handleBidPartnerUpdate,
    partnerBusinessArea,
    managerCheckBoxArr,
    updateAccountPayable,
    handleSnackbarOpen,
  ]);

  const { data: partnerList } = PARTNER_QUERY.useGetPartnerList({
    page: currentPage,
    perPage,
    transportMode,
    businessArea: partnerBusinessArea,
    shipmentType: bidDetail.isImport ? "importation" : "exportation",
    ...objectWithTermSearchTypeKey,
    enabled: true,
    region,
  });

  const handlePartnerManagersListModalOpen = useCallback(
    (managers: PartnerManagerItem[]) => {
      return () => {
        setManagers(managers);
        setShowsPartnerManagersListModal(true);
      };
    },
    []
  );

  const headCells: LegacyTableHeadCell<CellId>[] = useMemo(() => {
    return [
      { id: "checkbox", disablePadding: false, label: "선택", width: 50 },
      { id: "id", disablePadding: false, label: "ID", width: 80 },
      { id: "name", disablePadding: false, label: "회사명" },
      { id: "language", disablePadding: false, label: "언어", width: 70 },
      {
        id: "countries",
        disablePadding: false,
        label: "담당 국가",
        width: 140,
      },
      {
        id: "mainManagerName",
        disablePadding: false,
        label: "담당자 이름",
        width: 100,
      },
      {
        id: "mainManagerPosition",
        disablePadding: false,
        label: "직함",
        width: 100,
      },
      {
        id: "mainManagerPhone",
        disablePadding: false,
        label: "전화번호",
        width: 150,
      },
      {
        id: "mainManagerEmail",
        disablePadding: false,
        label: "이메일",
        width: 200,
      },
      {
        id: "showsManagersModal",
        disablePadding: false,
        label: "매니저",
        width: 100,
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!partnerList?.list) return [];

    return partnerList.list.map((partnerListItem) => {
      const row: LegacyTableBodyRow<CellId> = {
        checkbox: (
          <Checkbox
            checked={checkBoxSet.has(partnerListItem.id)}
            onClick={(e) => {
              e.stopPropagation();
              initCheckBoxSet([partnerListItem.id]);
              const idsToCheck = partnerListItem.managers.map(
                (managersItem) => {
                  return managersItem.id;
                }
              );
              initManagerCheckBoxSet(idsToCheck);
            }}
          />
        ),

        id: partnerListItem.id,
        name: partnerListItem.name,
        language: partnerListItem.language,
        countries: renderCountryInCharge({
          countryDataList: countryData,
          countryInChargeOfPartner: partnerListItem.countries,
        }),
        mainManagerName: getMainManagerInfo("name", partnerListItem.managers),
        mainManagerPosition: getMainManagerInfo(
          "position",
          partnerListItem.managers
        ),
        mainManagerPhone: getMainManagerInfo("phone", partnerListItem.managers),
        mainManagerEmail: getMainManagerInfo("email", partnerListItem.managers),
        showsManagersModal: (
          <Button
            onClick={handlePartnerManagersListModalOpen(
              partnerListItem.managers
            )}
          >
            보기
          </Button>
        ),
      };
      return row;
    });
  }, [
    checkBoxSet,
    countryData,
    handlePartnerManagersListModalOpen,
    initCheckBoxSet,
    initManagerCheckBoxSet,
    partnerList?.list,
  ]);

  const handleModalClose = useCallback(() => {
    setShowsBidPartnerModal(false);
  }, [setShowsBidPartnerModal]);

  return (
    <>
      <Modal
        isOpened={showsBidPartnerModal}
        handleClose={handleModalClose}
        modalBody={
          <>
            <LegacyTable
              toolbarItems={{
                left: [
                  TermSearchPanel,
                  <Button
                    key="request-button"
                    variant="contained"
                    onClick={handleAccountPayableUpdate}
                  >
                    파트너 선택
                  </Button>,
                ],
              }}
              sx={{ height: "500px", width: "1400px" }}
              title="의뢰 파트너 리스트"
              headCells={headCells}
              rows={rows}
              pagination={{
                totalCount: partnerList?.total || 0,
                perPage,
                setPerPage,
                currentPage,
                setCurrentPage,
              }}
            />

            {showsPartnerManagersListModal && (
              <PartnerManagersListModal
                showsPartnerManagersListModal={showsPartnerManagersListModal}
                setShowsPartnerManagersListModal={
                  setShowsPartnerManagersListModal
                }
                managers={managers}
                partnerCheckboxSet={checkBoxSet}
                managerCheckBoxSet={managerCheckBoxSet}
                toggleManagerCheckBox={toggleManagerCheckBox}
                initManagerCheckBoxSet={initManagerCheckBoxSet}
              />
            )}
          </>
        }
      />

      {ResponseHandlerOfUpdateBidPartner}
      {ResponseHandlerOfUpdateAccountPayable}
    </>
  );
}

export default BidPartnerListModal;
