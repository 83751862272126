import { useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useIsFetching } from "react-query";
import { Box, Button, CircularProgress, Typography } from "@mui/material";

import AutoCompleteWithReactHookForm from "@sellernote/shared/src/admin-ui/components/AutoCompleteWithReactHookForm";
import CheckboxWithReactHookForm from "@sellernote/shared/src/admin-ui/components/CheckboxWithReactHookForm";
import Modal from "@sellernote/shared/src/admin-ui/components/Modal";
import RadioGroupWithReactHookForm from "@sellernote/shared/src/admin-ui/components/RadioGroupWithReactHookForm";
import TextFieldWithReactHookForm from "@sellernote/shared/src/admin-ui/components/TextFieldWithReactHookForm";
import { inlandTransportTypeRadioGroupOptionList } from "@sellernote/shared/src/admin-ui/pageContainers/bid/constants";
import QuotationResetModal from "@sellernote/shared/src/admin-ui/pageContainers/bid/detail/components/QuotationResetModal";
import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import { ADMIN_ZONE_ADDRESS_QUERY_KEY_GEN } from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_ZONE_ADDRESS_QUERY";
import {
  AdminBidDetail,
  AdminShipmentInfoForm,
} from "@sellernote/shared/src/types/forwarding/adminBid";

import {
  getBooleanStringV2,
  getExportShipmentUpdatePayload,
  getFormWithDirty,
  getImportShipmentUpdatePayload,
  getIncotermsDefaultValueAndOptionList,
} from "./utils";

import {
  containerAccessableRadioGroupOptionList,
  containerStuffingRadioGroupOptionList,
  inclusionConditionGroupOptionList,
  inlandTypeRadioGroupOptions,
} from "./constants";
import TransportInfo from "./TransportInfo";
import useShipmentInfoUpdateRequest from "./useShipmentInfoUpdateRequest";

function UpdateShipmentInfo({ bidDetail }: { bidDetail: AdminBidDetail }) {
  const { handleSnackbarOpen } = useSnackbar();

  const [opensModal, setOpensModal] = useState(false);

  const { isImport, freightType } = bidDetail;

  const defaultValues: AdminShipmentInfoForm = {
    incoterms: bidDetail.incoterms,
    startPortId: bidDetail.startPortId,
    startViaPortId: bidDetail.startViaPortId,
    startType: bidDetail.startType,
    startAddress: bidDetail.startAddress,
    endPortId: bidDetail.endPortId,
    endViaPortId: bidDetail.endViaPortId,
    endType: bidDetail.endType,
    endAddress: bidDetail.endAddress,
    endAddressDetail: bidDetail.endAddressDetail,
    zoneId: bidDetail.zoneId,
    containerAccessable: getBooleanStringV2(bidDetail.containerAccessable),
    containerStuffing: getBooleanStringV2(bidDetail.containerStuffing),
    inlandType: bidDetail.inlandType,
    inlandTransportType: bidDetail.originInland,
    containLss: getBooleanStringV2(bidDetail.containLss),
    containOceanSurcharge: getBooleanStringV2(bidDetail.containOceanSurcharge),
    containDomesticFee: getBooleanStringV2(bidDetail.containDomesticFee),
    hopeCargoInsurance: bidDetail.hopeCargoInsurance,
    importCustoms: bidDetail.containCustoms,
    needFTACertificateAgency: bidDetail.needFTACertificateAgency,
    useVGM: bidDetail.useVGM,
    exportCustoms: bidDetail.containExportCustoms,
    userNote: bidDetail.userNote || "",
    wareHouseId: bidDetail.wareHouseId,
  };

  const methods = useForm<AdminShipmentInfoForm>({
    mode: "onChange",
    defaultValues,
  });

  const {
    control,
    formState: { isDirty, dirtyFields },
    getValues,
    watch,
    reset,
    handleSubmit,
  } = methods;

  const isZoneAddressFetching = useIsFetching(
    ADMIN_ZONE_ADDRESS_QUERY_KEY_GEN.getAdminZoneAddress({
      address: isImport
        ? getValues("endAddress") ?? ""
        : getValues("startAddress") ?? "",
    })
  );

  const startType = useWatch({ control, name: "startType" });

  const endType = useWatch({ control, name: "endType" });

  const incoterms = useWatch({ control, name: "incoterms" });

  const incotermsDefaultValueAndOptions = getIncotermsDefaultValueAndOptionList(
    {
      freightType: freightType,
      isExport: !isImport,
      endType: endType,
      hopeCargoInsurance: bidDetail.hopeCargoInsurance,
      importCustoms: bidDetail.importCustoms,
    }
  );

  const {
    handleRecalculationCheckAndUpdate,
    handleShipmentInfoWithoutRecalculationUpdate,
    showsQuotationResetModal,
    setShowsQuotationResetModal,
    ResponseHandler: ResponseHandlerOfShipmentInfoUpdateRequest,
  } = useShipmentInfoUpdateRequest({
    requestPayload: isImport
      ? getImportShipmentUpdatePayload(
          getFormWithDirty({ dirtyFields, formData: watch() })
        )
      : getExportShipmentUpdatePayload(
          getFormWithDirty({ dirtyFields, formData: watch() })
        ),
    bidDetail,
    closeModal: () => {
      reset(getValues());
      setOpensModal(false);
    },
  });

  const handleUpdate = () => {
    if (isZoneAddressFetching) {
      return;
    }
    if (isDirty) {
      handleRecalculationCheckAndUpdate();
    } else {
      handleSnackbarOpen("변경사항이 없습니다.", "warning");
    }
  };

  return (
    <>
      <Button variant="contained" onClick={() => setOpensModal(true)}>
        구간 정보 및 확인 사항 수정
      </Button>

      <Modal
        isOpened={opensModal}
        handleClose={() => {
          reset(defaultValues);
          setOpensModal(false);
        }}
        modalBodySx={{ maxHeight: window.innerHeight - 40 }}
        modalBody={
          <FormProvider {...methods}>
            <Box
              component="form"
              sx={{ display: "flex", flexDirection: "column", rowGap: "32px" }}
              onSubmit={handleSubmit(handleUpdate)}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(5, 1fr)",
                  columnGap: "24px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "16px",
                    gridColumn: "span 3",
                  }}
                >
                  <Box>
                    <Typography variant="subtitle1" fontWeight={700}>
                      인코텀즈
                    </Typography>

                    <Box>
                      <AutoCompleteWithReactHookForm
                        name="incoterms"
                        control={control}
                        options={incotermsDefaultValueAndOptions.options}
                        required={true}
                      />
                    </Box>
                  </Box>

                  <TransportInfo bidDetail={bidDetail} />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "16px",
                    gridColumn: "span 2",
                  }}
                >
                  {((isImport && endType === "inland") ||
                    (!isImport && startType === "inland")) && (
                    <Box>
                      <Typography variant="subtitle1" fontWeight={700}>
                        내륙 운송
                      </Typography>

                      {isImport ? (
                        freightType === "FCL" ? (
                          <Box>
                            <Typography variant="subtitle2">
                              도착지 컨테이너 진입여부
                            </Typography>

                            <RadioGroupWithReactHookForm
                              name="containerAccessable"
                              control={control}
                              radioGroupOption={
                                containerAccessableRadioGroupOptionList
                              }
                              required={true}
                            />
                          </Box>
                        ) : (
                          <Box>
                            <Typography variant="subtitle2">
                              도착지 운송 방식
                            </Typography>

                            <RadioGroupWithReactHookForm
                              name="inlandType"
                              control={control}
                              required={true}
                              radioGroupOption={inlandTypeRadioGroupOptions}
                            />
                          </Box>
                        )
                      ) : freightType === "FCL" ? (
                        <Box>
                          <Typography variant="subtitle2">
                            출발지 컨테이너 진입여부
                          </Typography>

                          <RadioGroupWithReactHookForm
                            name="containerStuffing"
                            control={control}
                            required={true}
                            radioGroupOption={
                              containerStuffingRadioGroupOptionList
                            }
                          />
                        </Box>
                      ) : (
                        <Box>
                          <Typography variant="subtitle2">
                            출발지 운송 방식
                          </Typography>

                          <RadioGroupWithReactHookForm
                            name="inlandTransportType"
                            control={control}
                            required={true}
                            radioGroupOption={
                              inlandTransportTypeRadioGroupOptionList
                            }
                          />
                        </Box>
                      )}
                    </Box>
                  )}

                  {isImport && (
                    <Box>
                      <Typography variant="subtitle1" fontWeight={700}>
                        비용 관련 사항
                      </Typography>

                      <Box>
                        {bidDetail.serviceType !== "consolidation" && (
                          <>
                            <Box>
                              <Typography variant="subtitle2">
                                국내부대비용
                              </Typography>

                              <RadioGroupWithReactHookForm
                                name="containDomesticFee"
                                control={control}
                                radioGroupOption={
                                  inclusionConditionGroupOptionList
                                }
                                required={true}
                              />
                            </Box>

                            {freightType !== "AIR" && (
                              <Box>
                                <Typography variant="subtitle2">
                                  Ocean Surcharge
                                </Typography>

                                <RadioGroupWithReactHookForm
                                  name="containOceanSurcharge"
                                  control={control}
                                  radioGroupOption={
                                    inclusionConditionGroupOptionList
                                  }
                                  required={true}
                                />
                              </Box>
                            )}
                          </>
                        )}

                        <Box>
                          <Typography variant="subtitle2">LSS 납부</Typography>

                          <RadioGroupWithReactHookForm
                            name="containLss"
                            control={control}
                            radioGroupOption={inclusionConditionGroupOptionList}
                            required={true}
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}

                  <Box display="flex" flexDirection="column">
                    <Typography variant="subtitle1" fontWeight={700}>
                      부가서비스
                    </Typography>

                    {isImport ? (
                      <>
                        <CheckboxWithReactHookForm
                          name="importCustoms"
                          control={control}
                          label="수입통관업무 대행"
                        />

                        <CheckboxWithReactHookForm
                          name="needFTACertificateAgency"
                          control={control}
                          label="FTA 원산지증명서 대행발급"
                        />
                      </>
                    ) : (
                      <>
                        <CheckboxWithReactHookForm
                          name="importCustoms"
                          control={control}
                          label="수입통관업무 대행"
                          disabled={incoterms !== "DDP" && incoterms !== "DAP"}
                        />

                        <CheckboxWithReactHookForm
                          name="exportCustoms"
                          control={control}
                          label="수출통관업무 대행"
                        />

                        <CheckboxWithReactHookForm
                          name="useVGM"
                          control={control}
                          label="VGM"
                          disabled={freightType !== "FCL"}
                        />
                      </>
                    )}

                    <CheckboxWithReactHookForm
                      name="hopeCargoInsurance"
                      control={control}
                      label="적하보험 가입"
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "4px",
                    }}
                  >
                    <Typography variant="subtitle1" fontWeight={700}>
                      요청사항
                    </Typography>

                    <TextFieldWithReactHookForm
                      name="userNote"
                      control={control}
                      placeholder="고객 요청사항"
                      multiline
                    />
                  </Box>
                </Box>
              </Box>

              <Button type="submit" variant="contained">
                {isZoneAddressFetching ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "수정"
                )}
              </Button>
            </Box>
          </FormProvider>
        }
      />

      {showsQuotationResetModal && (
        <QuotationResetModal
          showsQuotationResetModal={showsQuotationResetModal}
          onQuotationResetModalClose={() => setShowsQuotationResetModal(false)}
          onShipmentItemCargoUpdate={
            handleShipmentInfoWithoutRecalculationUpdate
          }
        />
      )}

      {ResponseHandlerOfShipmentInfoUpdateRequest}
    </>
  );
}

export default UpdateShipmentInfo;
