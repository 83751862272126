import {
  AccountBusinessArea,
  AccountCategory,
  AccountListInfo,
  AccountPartnerInfo,
  AccountPaymentCondition,
  AccountTeamInfo,
} from "@sellernote/shared/src/types/forwarding/adminAccount";
import { replaceEmptyToDash } from "@sellernote/shared/src/utils/common/string";

const paymentConditionToKR = (condition: AccountPaymentCondition | null) => {
  if (!condition) return "-";

  return condition === "daily" ? "일 정산" : "월 정산";
};

const categoryToKR = (category: AccountCategory | null) => {
  if (!category) return "-";

  if (category === "all") return "모두";

  return category === "sales" ? "매출" : "매입";
};

const businessAreaToKR = (businessArea: AccountBusinessArea) => {
  return businessArea === "domestic" ? "국내" : "해외";
};

const showPartner = (list: AccountPartnerInfo[]) => {
  if (!list.length) return "-";

  const getDisplayName = (partner: AccountPartnerInfo) => partner.name;

  const [first, second] = list.map(getDisplayName);

  if (list.length <= 2) return [first, second].filter(Boolean).join(", ");

  return `${first}, ${second} 외`;
};

const showTeam = (list: AccountTeamInfo[]) => {
  if (!list.length) return "-";

  const getDisplayName = (team: AccountTeamInfo) => team.company || team.name;

  const [first, second] = list.map(getDisplayName);

  if (list.length <= 2) return [first, second].filter(Boolean).join(", ");

  return `${first}, ${second} 외`;
};

const getRowContent = ({
  accountList,
  setAccountDetailId,
}: {
  accountList: AccountListInfo[];
  setAccountDetailId: (val: number | undefined) => void;
}) => {
  const rowList = accountList.map((account) => {
    return {
      handleRowClick: () => setAccountDetailId(account.id),

      "No.": account.id,
      구분: businessAreaToKR(account.businessArea),
      거래형태: categoryToKR(account.category),
      거래처명: account.name,
      사업자등록번호: account.BRN?.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3"),
      "대표자 성명": replaceEmptyToDash(account.representativeName),
      "업무 이메일": replaceEmptyToDash(account.managerEmail),
      "세금 이메일": replaceEmptyToDash(account.taxEmail),
      지급조건: paymentConditionToKR(account.paymentCondition),
      팀명: showTeam(account.teams),
      파트너명: showPartner(account.partners),
    };
  });

  return rowList;
};

export { getRowContent };
