import ADMIN_BID_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import ADMIN_COMMON_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_COMMON_QUERY";
import { ApplyBidFeeData } from "@sellernote/shared/src/types/forwarding/adminBid";

export const removePreviousItemPrice = ({
  feeList,
}: {
  feeList: ApplyBidFeeData[];
}) => {
  return feeList.map((fee) => {
    const { previousItemPrice, ...newFee } = fee;
    return newFee;
  });
};

/**
 * 견적서 관련 페이지의 get 데이터 요청을 묶은 커스텀 훅
 */
function useQuotationData({ bidId }: { bidId: number }) {
  const { data: bidDetail, isFetching: isShipmentDetailFetching } =
    ADMIN_BID_QUERY.useGetAdminBidDetail({ bidId });

  const { data: linerList } = ADMIN_COMMON_QUERY.useGetLinerList();

  const { data: exchangeRateList } = ADMIN_COMMON_QUERY.useGetExchangeRateList(
    {
      all: true,
      countryTarget: bidDetail?.locale,
    },
    // enabled 설정
    bidDetail?.locale !== undefined
  );

  const { data: applyItems } = ADMIN_BID_QUERY.useGetAdminBidApplyItems({
    isImport: bidDetail?.isImport,
    enabled: bidDetail?.isImport !== undefined,
  });

  return {
    bidDetail,
    exchangeRateList,
    applyItems,
    linerList,
    isShipmentDetailFetching,
  };
}

export default useQuotationData;
