import {
  Control,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import TemplateSearch from "@sellernote/shared/src/admin-ui/containers/TemplateSearch";
import { GET_ADMIN_BID_APPLY_ITEMS_RES } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminBid";
import {
  AdminBidApplyCostType,
  ApplyBidFormData,
} from "@sellernote/shared/src/types/forwarding/adminBid";
import {
  TemplateCategory,
  TemplateFeeDataType,
} from "@sellernote/shared/src/types/forwarding/adminTemplate";
import {
  ExchangeRate,
  TrelloBidDetail,
} from "@sellernote/shared/src/types/forwarding/trello";
import { getChangeTemplateCategoryToKr } from "@sellernote/shared/src/utils/forwarding/admin/adminTemplate";

import ApplyBidFormList from "./ApplyBidFormList";
import CurrencySelect from "./CurrencySelect";

interface QuotationFeeFormProps {
  // 견적서 템플릿에 필요한 props
  bidId?: number;
  reset?: UseFormReset<ApplyBidFormData>;
  watch?: UseFormWatch<ApplyBidFormData>;
  templateType?: "bidApply" | "withdrawal";
  bidAccountPayableId?: number;
  templateFeeDataType?: TemplateFeeDataType;

  // 공통 props
  setValue: UseFormSetValue<ApplyBidFormData>;
  quotationFeeFormCategory: Exclude<AdminBidApplyCostType, "etc">;

  // 견적서 항목 폼의 필요한 props
  applyShipmentFormFeeDataType: Exclude<
    TemplateFeeDataType,
    "all" | "withdrawalFormList"
  >;
  control: Control<ApplyBidFormData>;
  exchangeRateList: ExchangeRate[];
  applyItems: GET_ADMIN_BID_APPLY_ITEMS_RES;
  bidDetail?: TrelloBidDetail;
  showsPreviousItemPrice?: boolean;

  // 거래명세서에서 AIR일 때 직접입력하는 rton 값
  directAirRton?: number;

  readonly?: boolean;
}

const formCommonStyle = {
  padding: 2,
  borderRadius: 1,
  border: `1px solid ${grey[400]}`,
};

/**
 * TODO: 개선해야할 점
 * 거래명세서, 견적서 동시 사용 중 분리가 필요
 * 분리에 따른 타입명 수정이 필요
 */
function QuotationFeeForm({
  bidId,
  reset,
  watch,
  templateType,
  bidAccountPayableId,
  templateFeeDataType,
  setValue,
  quotationFeeFormCategory,
  applyShipmentFormFeeDataType,
  control,
  exchangeRateList,
  applyItems,
  bidDetail,
  showsPreviousItemPrice,
  directAirRton,
  readonly = false,
}: QuotationFeeFormProps) {
  /** 거래명세서에서는 템플릿 기능을 사용하지 않음 이때 props에서 해당 값들은 존재하지 않음 */
  const showsTemplate =
    watch &&
    reset &&
    templateType &&
    quotationFeeFormCategory &&
    templateFeeDataType;

  return (
    <Grid item container xs={12} sx={formCommonStyle}>
      <Grid item container xs={12} justifyContent="space-between">
        <Grid item>
          <Typography color="primary" variant="h6">
            {getChangeTemplateCategoryToKr(quotationFeeFormCategory)}
          </Typography>
        </Grid>

        {!readonly && (
          <Grid item container xs={11} gap={1} justifyContent={"space-between"}>
            <Grid item>
              <CurrencySelect
                setValue={setValue}
                exchangeRateList={exchangeRateList}
                feeDataType={applyShipmentFormFeeDataType}
                control={control}
              />
            </Grid>

            {showsTemplate && (
              <Grid item>
                <TemplateSearch
                  bidId={bidId}
                  /** TemplateCategory의 공통된 하위 타입이 들어와 as를 사용 */
                  templateCategory={
                    quotationFeeFormCategory as TemplateCategory
                  }
                  reset={reset}
                  watch={watch}
                  templateFeeDataType={templateFeeDataType}
                  setValue={setValue}
                  templateType={templateType}
                  exchangeRateList={exchangeRateList}
                  bidAccountPayableId={bidAccountPayableId}
                />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>

      <Grid item xs={12}>
        <ApplyBidFormList
          applyShipmentFormFeeDataType={applyShipmentFormFeeDataType}
          applyItemListCostType={
            /** AdminBidApplyCostType의 공통된 하위 타입이 들어와 as를 사용 */
            quotationFeeFormCategory as AdminBidApplyCostType
          }
          control={control}
          applyItems={applyItems}
          exchangeRateList={exchangeRateList}
          setValue={setValue}
          bidDetail={bidDetail}
          showsPreviousItemPrice={showsPreviousItemPrice}
          directAirRton={directAirRton}
          readonly={readonly}
        />
      </Grid>
    </Grid>
  );
}

export default QuotationFeeForm;
