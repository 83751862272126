import { useCallback } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Grid } from "@mui/material";

import { RadioGroupWithReactHookFormOption } from "@sellernote/shared/src/admin-ui/components/RadioGroupWithReactHookForm";
import ADMIN_ZONE_ADDRESS_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_ZONE_ADDRESS_QUERY";
import {
  InlnadTransportType,
  Port,
} from "@sellernote/shared/src/types/common/common";
import { BidTransportType } from "@sellernote/shared/src/types/forwarding/bid";
import { useDebounce } from "@sellernote/shared/src/utils/common/hook";

import Export from "./Export";
import Import from "./Import";

const transPortTypeRadioGroupOptions: RadioGroupWithReactHookFormOption<BidTransportType>[] =
  [
    {
      label: "내륙",
      value: "inland",
    },
    {
      label: "해상",
      value: "sea",
    },
    {
      label: "항공",
      value: "air",
    },
  ];

export const inlandTypeRadioGroupOptions: RadioGroupWithReactHookFormOption<InlnadTransportType>[] =
  [
    {
      label: "독차",
      value: "sole",
    },
    {
      label: "합차",
      value: "consol",
    },
    {
      label: "택배",
      value: "parcel",
    },
  ];

function TransportSection({ portList }: { portList: Port[] }) {
  const { watch, control } = useFormContext();

  const endAddress = useWatch({
    control,
    name: "endAddress",
  });

  const bidType = useWatch({
    control,
    name: "bidType",
  });

  const startAddress = useWatch({
    control,
    name: "startAddress",
  });

  const importCarrier = useWatch({
    control,
    name: "importCarrier",
  });

  const freightType = useWatch({
    control,
    name: "freightType",
  });

  // 지역 검색 텀을 둠(지연시간은 useSearchWithTerm 반영)

  const debouncedEndAddress = useDebounce(endAddress, 500);
  const debouncedStartAddress = useDebounce(startAddress, 500);

  const { data: zoneAddress } = ADMIN_ZONE_ADDRESS_QUERY.useGetAdminZoneAddress(
    {
      address:
        bidType === "export" ? debouncedStartAddress : debouncedEndAddress,
    }
  );

  const getPortNameListOptions = useCallback(
    (type: "startType" | "endType") => {
      const transportType = watch(type);
      // 중복된 항구명을 제거하기 위해 Set을 사용
      return [
        ...new Set(
          portList
            .filter((portItem) => {
              if (transportType === "inland") {
                if (freightType === "AIR") {
                  return portItem.type === "air";
                }
                return portItem.type === "sea";
              }
              return transportType === portItem.type;
            })

            .filter((portItem) => {
              if (bidType === "import") {
                if (type === "startType") {
                  return portItem.country !== "KR";
                }

                return portItem.country === "KR";
              }

              if (bidType === "export") {
                if (type === "endType") {
                  return portItem.country !== "KR";
                }

                return portItem.country === "KR";
              }

              return portItem;
            })

            // 코드명, 영문명, 한글명이 다 표시되어야 함
            .map((portItem) => {
              return `${portItem.nameEN}(${portItem.name ?? ""}${
                portItem.code ? "/" : ""
              }${portItem.code ?? ""})`;
            })
        ),
      ];
    },
    [bidType, freightType, portList, watch]
  );

  /** 출발지 운송타입 */
  const startTypeRadioGroupOption = transPortTypeRadioGroupOptions.map(
    (option) => {
      if (freightType === "AIR") {
        return {
          ...option,
          /** AIR일 때 해상은 disabled 만약 C 조건이라면 내륙도 disabled */
          disabled:
            option.value === "sea" ||
            (option.value === "inland" && importCarrier === "customer")
              ? true
              : false,
        };
      }

      return {
        ...option,
        /** FCL, LCL 때 항공은 disabled 만약 C 조건이라면 내륙도 disabled */
        disabled:
          option.value === "air" ||
          (option.value === "inland" && importCarrier === "customer")
            ? true
            : false,
      };
    }
  );

  /** 도착지 운송타입 */
  const endTypeRadioGroupOption = transPortTypeRadioGroupOptions.map(
    (option) => {
      if (freightType === "AIR") {
        return {
          ...option,
          /** AIR일 때 해상은 disabled 만약 C 조건이라면 내륙만 선택 가능 */
          disabled:
            option.value === "sea" ||
            (option.value === "air" && importCarrier === "customer")
              ? true
              : false,
        };
      }

      return {
        ...option,
        /** FCL, LCL 때 항공은 disabled 만약 C 조건이라면 내륙만 선택 가능 */
        disabled:
          option.value === "air" ||
          (option.value === "sea" && importCarrier === "customer")
            ? true
            : false,
      };
    }
  );

  return (
    <Grid item container spacing={3} alignItems="base-line" xs={12}>
      {/* 수입/수출이 출/도착지 내륙 로직이 서로 반대로 된다는 것 정도만 제외하면 거의 동일한 코드라 합칠 수 있으나, 추후 쉽다 쪽 의뢰생성을 사용할 것이므로 일단 이대로 둔다 */}
      {bidType === "export" ? (
        <Export
          startAddress={startAddress}
          zoneAddress={zoneAddress}
          getPortNameListOptions={getPortNameListOptions}
          startTypeRadioGroupOption={startTypeRadioGroupOption}
          endTypeRadioGroupOption={endTypeRadioGroupOption}
        />
      ) : (
        <Import
          endAddress={endAddress}
          importCarrier={importCarrier}
          zoneAddress={zoneAddress}
          getPortNameListOptions={getPortNameListOptions}
          startTypeRadioGroupOption={startTypeRadioGroupOption}
          endTypeRadioGroupOption={endTypeRadioGroupOption}
        />
      )}
    </Grid>
  );
}

export default TransportSection;
