import { useCallback } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Box,
  Button,
  CardContent,
  CardHeader,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";

import { AdminBidDetail } from "@sellernote/shared/src/types/forwarding/adminBid";
import { Language } from "@sellernote/shared/src/types/forwarding/customs";
import { PartnerBusinessArea } from "@sellernote/shared/src/types/forwarding/partner";

import { useBidPartnerContext } from "../../_hooks/useBidPartnerContext";

export default function List({
  type,
  canEdit,
  setShowsBidPartnerModal,
  bidDetail,
  hasExporterInfo,
}: {
  type: "domestic" | "foreign";
  canEdit: boolean;
  setShowsBidPartnerModal: (value: boolean) => void;
  bidDetail: AdminBidDetail;
  hasExporterInfo: boolean;
}) {
  const {
    setPartnerBusinessArea,
    setBidAccountPayableId,
    setLanguage,
    setPartnerName,
    showsRequestEmailToPartner,
    setShowsRequestEmailToPartner,
    handleSnackbarOpen,
  } = useBidPartnerContext();

  const partnerList = bidDetail.accountPayables.filter((v) => {
    return v.domain === type;
  });

  const title = type === "domestic" ? "국내파트너" : "해외파트너";

  /**
   * 국내/해외파트너는 수입/수출 여부에 따라 실제 어떤 역할을 하는 파트너인지 구별된다.
   * - 참고: 파트너 정의 - https://www.notion.so/shipda/12e783b6edd48020b782c079207a7ffb
   */
  const subTitle = (() => {
    switch (type) {
      case "domestic":
        return "부킹파트너";
      case "foreign":
        return bidDetail.isImport ? "부킹파트너" : "현지파트너";
    }
  })();

  /**
   * accountPayableId를 0으로 주면 add, 아니면 edit
   */
  const createPartnerAddOrEditHandler = useCallback(
    (accountPayableId: number, businessArea: PartnerBusinessArea) => {
      return () => {
        if (!canEdit) {
          handleSnackbarOpen("정산완료에서는 수정할 수 없습니다.", "warning");
        } else {
          setBidAccountPayableId(accountPayableId);
          setPartnerBusinessArea(businessArea);
          setShowsBidPartnerModal(true);
        }
      };
    },
    [
      canEdit,
      handleSnackbarOpen,
      setBidAccountPayableId,
      setPartnerBusinessArea,
      setShowsBidPartnerModal,
    ]
  );

  const createPartnerContactHandler = useCallback(
    ({
      accountPayableId,
      businessArea,
      language,
      partnerName,
    }: {
      accountPayableId: number;
      businessArea: PartnerBusinessArea;
      language: Language;
      partnerName: string;
    }) => {
      return () => {
        if (!hasExporterInfo) {
          handleSnackbarOpen(
            bidDetail.isImport
              ? "수출자 정보를 등록해주세요."
              : "수입자 정보를 등록해주세요.",
            "warning"
          );
          return;
        }

        if (!bidDetail.management.forwardingManagerId) {
          handleSnackbarOpen("담당자를 등록해주세요.", "warning");
          return;
        }

        if (!canEdit) {
          handleSnackbarOpen("정산완료에서는 컨택 할 수 없습니다.", "warning");
          return;
        }

        setBidAccountPayableId(accountPayableId);
        setPartnerBusinessArea(businessArea);
        setLanguage(language);
        setPartnerName(partnerName);
        setShowsRequestEmailToPartner(true);
      };
    },
    [
      bidDetail.isImport,
      bidDetail.management.forwardingManagerId,
      canEdit,
      handleSnackbarOpen,
      hasExporterInfo,
      setBidAccountPayableId,
      setLanguage,
      setPartnerBusinessArea,
      setPartnerName,
      setShowsRequestEmailToPartner,
    ]
  );

  return (
    <Paper sx={{ minWidth: "340px" }}>
      <CardHeader
        title={
          <>
            <Typography variant="subtitle1">{title}</Typography>
          </>
        }
        subheader={
          <Typography variant="caption" color={grey[500]}>
            {subTitle}
          </Typography>
        }
        action={
          <Button
            variant="contained"
            endIcon={<AddCircleIcon />}
            size="small"
            color="success"
            sx={{ marginLeft: "20px" }}
            onClick={createPartnerAddOrEditHandler(0, type)}
          >
            추가
          </Button>
        }
      />
      <Divider />
      <CardContent>
        <Stack spacing={2}>
          {partnerList.map((v) => {
            return (
              <Box
                key={v.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <AccountCircleIcon sx={{ paddingRight: "2px" }} />{" "}
                  {v.partner?.name || "파트너 미지정"}
                </Box>

                <Box sx={{ display: "flex", gap: "2px" }}>
                  <Button
                    variant="outlined"
                    onClick={createPartnerAddOrEditHandler(v.id, v.domain)}
                    size="small"
                  >
                    수정
                  </Button>

                  {v.partner && (
                    <Button
                      variant="contained"
                      onClick={createPartnerContactHandler({
                        accountPayableId: v.id,
                        businessArea: v.domain,
                        language: v.partner.language,
                        partnerName: v.partner.name,
                      })}
                      size="small"
                    >
                      컨택
                    </Button>
                  )}
                </Box>
              </Box>
            );
          })}
        </Stack>
      </CardContent>
    </Paper>
  );
}
