import { BidCreateCheckPoints } from "@sellernote/shared/src/types/forwarding/adminBidCreate";

function getCheckPointLabel(checkPoint: BidCreateCheckPoints) {
  switch (checkPoint) {
    case "containLss":
      return "LSS";
    case "containDomesticFee":
      return "국내부대비용";
    case "containOceanSurcharge":
      return "Ocean Surcharge";
    case "containerAccessable":
      return "도착지 컨테이너 진입여부";
    case "containsWarehouseFee":
      return "창고보관료(7일)";
    case "containerStuffing":
      return "출발지 컨테이너 진입가능";
    case "inlandTransportType":
      return "운송방식";
    default:
      return "";
  }
}

export { getCheckPointLabel };
