import { useMemo } from "react";
import { useQueryClient } from "react-query";

import Modal from "@sellernote/shared/src/admin-ui/components/Modal";
import { ADMIN_BID_QUERY_KEY_GEN } from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import {
  AdminBidDetail,
  AdminBidPartnerQuoteReceiverList,
} from "@sellernote/shared/src/types/forwarding/adminBid";

import ConsolidationItemsNameForm from "./ConsolidationItemsNameForm";
import ContainersInfoNameForm from "./ContainersInfoNameForm";
import ProductsInfoNameForm from "./ProductsInfoNameForm";

export default function RequestQuoteToPartnerModal({
  bidDetail,
  showsRequestQuoteModal,
  setShowsRequestQuoteModal,
  receiverList,
  handlePartnerQuoteListRefetch,
  isBidCopy,
  handleBidCopyModalClose,
  copiedBidId,
}: {
  bidDetail: AdminBidDetail;
  showsRequestQuoteModal: boolean;
  setShowsRequestQuoteModal: (v: boolean) => void;
  receiverList: AdminBidPartnerQuoteReceiverList[];
  handlePartnerQuoteListRefetch?: () => void;
  isBidCopy: boolean;
  handleBidCopyModalClose?: () => void;
  copiedBidId?: number;
}) {
  const queryClient = useQueryClient();

  const needItemNameEn = useMemo(() => {
    const languageEnReceiver = receiverList.find((receiver) => {
      return receiver.language === "en";
    });

    if (languageEnReceiver) {
      return true;
    }

    return false;
  }, [receiverList]);

  const PartnerQuoteForm = useMemo(() => {
    /**
     * 의뢰 상세 데이터 갱신 함수
     * - "의뢰받음" 상태에서 파트너 견적 요청을 하는 경우 자동으로 "견적산출 중"으로 변경되기 때문에 의뢰 상세 데이터를 갱신한다.
     * - 의뢰복사인 경우에는 상태가 결정되기 때문에 사용하지 않음
     */
    const refetchShipmentDetail = () => {
      queryClient.invalidateQueries(
        ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
          bidId: bidDetail.id,
        })
      );
    };

    if (bidDetail.serviceType === "consolidation") {
      return (
        <ConsolidationItemsNameForm
          bidDetail={bidDetail}
          receiverList={receiverList}
          handlePartnerQuoteListRefetch={handlePartnerQuoteListRefetch}
          needItemNameEn={needItemNameEn}
          isBidCopy={isBidCopy}
          handleBidCopyModalClose={handleBidCopyModalClose}
          copiedBidId={copiedBidId}
          refetchShipmentDetail={refetchShipmentDetail}
        />
      );
    }

    if (bidDetail.freightType === "FCL") {
      return (
        <ContainersInfoNameForm
          bidDetail={bidDetail}
          receiverList={receiverList}
          handlePartnerQuoteListRefetch={handlePartnerQuoteListRefetch}
          needItemNameEn={needItemNameEn}
          isBidCopy={isBidCopy}
          handleBidCopyModalClose={handleBidCopyModalClose}
          copiedBidId={copiedBidId}
          refetchShipmentDetail={refetchShipmentDetail}
        />
      );
    }

    return (
      <ProductsInfoNameForm
        bidDetail={bidDetail}
        receiverList={receiverList}
        handlePartnerQuoteListRefetch={handlePartnerQuoteListRefetch}
        needItemNameEn={needItemNameEn}
        isBidCopy={isBidCopy}
        handleBidCopyModalClose={handleBidCopyModalClose}
        copiedBidId={copiedBidId}
        refetchShipmentDetail={refetchShipmentDetail}
      />
    );
  }, [
    bidDetail,
    copiedBidId,
    handleBidCopyModalClose,
    handlePartnerQuoteListRefetch,
    isBidCopy,
    needItemNameEn,
    queryClient,
    receiverList,
  ]);

  return (
    <Modal
      modalBodySx={{
        maxWidth: "800px",
      }}
      isOpened={showsRequestQuoteModal}
      handleClose={() => setShowsRequestQuoteModal(false)}
      modalBody={PartnerQuoteForm}
    />
  );
}
