import { Control, Controller, FormState } from "react-hook-form";
import { Box, Grid, Typography } from "@mui/material";

import TextField from "@sellernote/shared/src/admin-ui/components/TextField";
import { REQUEST_PARTNER_EMAIL_REQ } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminBid";
import { AdminBidDetail } from "@sellernote/shared/src/types/forwarding/adminBid";
import { PartnerBusinessArea } from "@sellernote/shared/src/types/forwarding/partner";

/**
 * 파트너 컨택시, 화주사의 회사명이 있는지 국문, 영문 각각 확인
 * 존재하면 텍스트로 노출, 존재하지 않으면 입력 필드를 노출
 *
 * 입력시 컨택 요청 메일에 전송됨과 동시에 해당 사용자 정보에 반영
 */
export default function CompanyNameForm({
  control,
  errors,
  bidUser,
  partnerBusinessArea,
}: {
  control: Control<REQUEST_PARTNER_EMAIL_REQ>;
  errors: FormState<REQUEST_PARTNER_EMAIL_REQ>["errors"];
  bidUser: AdminBidDetail["user"];
  partnerBusinessArea: PartnerBusinessArea;
}) {
  const hasKorCompanyName = Boolean(bidUser.company);
  const hasEngCompanyName = Boolean(bidUser.engCompany);

  const isDomesticPartner = partnerBusinessArea === "domestic";
  const isForeignPartner = partnerBusinessArea === "foreign";

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box width="40%">
        <Typography variant="body1" className="sads">
          회사명(한글)
        </Typography>

        <Grid item>
          {hasKorCompanyName ? (
            <Typography
              variant="body1"
              className="sads"
              mt={1}
              fontWeight={"bold"}
            >
              {bidUser.company}
            </Typography>
          ) : (
            <Controller
              control={control}
              name={"company"}
              rules={{ required: isDomesticPartner }}
              render={({ field: { ref, ...field } }) => {
                return (
                  <TextField
                    {...field}
                    required={isDomesticPartner}
                    variant={"standard"}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    inputRef={ref}
                    placeholder="입력해주세요."
                    error={Boolean(errors.company)}
                    helperText={errors.company && "입력해주세요."}
                  />
                );
              }}
            />
          )}
        </Grid>
      </Box>

      <Box width="40%">
        <Typography variant="body1" className="sads">
          회사명(영문)
        </Typography>

        <Grid item>
          {hasEngCompanyName ? (
            <Typography
              variant="body1"
              className="sads"
              mt={1}
              fontWeight={"bold"}
            >
              {bidUser.engCompany}
            </Typography>
          ) : (
            <Controller
              control={control}
              name={"engCompany"}
              rules={{ required: isForeignPartner }}
              render={({ field: { ref, ...field } }) => {
                return (
                  <TextField
                    {...field}
                    variant={"standard"}
                    required={isForeignPartner}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    inputRef={ref}
                    placeholder="입력해주세요."
                    error={Boolean(errors.engCompany)}
                    helperText={errors.engCompany && "입력해주세요."}
                  />
                );
              }}
            />
          )}
        </Grid>
      </Box>
    </Box>
  );
}
