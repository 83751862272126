import { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Button, Grid, Typography } from "@mui/material";

import Layout from "@sellernote/shared/src/admin-ui/containers/Layout";
import Loading from "@sellernote/shared/src/componentsToMoveToV1/Loading";
import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import ADMIN_BID_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import { ApplyBidFormData } from "@sellernote/shared/src/types/forwarding/adminBid";
import { isNotEmptyObjectOrArray } from "@sellernote/shared/src/utils/common/etc";
import { getCautionI18nKeyListByTransportCargoType } from "@sellernote/shared/src/utils/forwarding/quoteCautions";

import ItemInfoForRead from "pages/bid/_components/ItemInfo/ItemInfoForRead";
import QuotationForm from "pages/bid/_components/QuotationForm";

import QuotationUserMailModal from "../_components/QuotationUserMailModal";
import useDefaultFeeData from "../_hooks/useDefaultFeeData";
import useQuotationData from "../_hooks/useQuotationData";
import useUpdateRequest from "../_hooks/useUpdateRequest";

const BidQuotationNew = () => {
  const { handleSnackbarOpen } = useSnackbar();

  const { id } = useParams<{ id: string }>();

  const { t } = useTranslation(["utils"]);

  const {
    bidDetail,
    exchangeRateList,
    applyItems,
    linerList,
    isShipmentDetailFetching,
  } = useQuotationData({ bidId: Number(id) });

  const { data: applyFares } = ADMIN_BID_QUERY.useGetAdminBidApplyFares({
    bidId: Number(id),
  });

  // 해당 운임 불러오기 여부
  const [isLoadedApplyFare, setIsLoadedApplyFare] = useState<boolean>(false);

  const { getDefaultFeeData } = useDefaultFeeData({
    bidDetail,
    applyItems,
    exchangeRateList,
  });

  const methods = useForm<ApplyBidFormData>({
    defaultValues: {
      freightPaymentType: bidDetail?.isImport ? "cc" : "pp",
      localFeeData: [],
      freightFeeData: [],
      domesticFeeData: [],
      inlandFeeData: [],
      otherFeeData: [],
      taxFeeData: [],
      expiredAt: new Date(),
      isTransit: false,
    },
  });

  const { watch, setValue } = methods;

  const defaultFeeDataUpdate = useCallback(() => {
    setValue("localFeeData", getDefaultFeeData("localCost"));
    setValue("freightFeeData", getDefaultFeeData("freightCost"));
    setValue("domesticFeeData", getDefaultFeeData("domesticCost"));
    setValue("inlandFeeData", getDefaultFeeData("inlandCost"));
    setValue("otherFeeData", getDefaultFeeData("otherCost"));
    setValue("taxFeeData", getDefaultFeeData("tax"));
    setValue("expiredAt", new Date());
    setValue("isTransit", false);

    setIsLoadedApplyFare(true);
  }, [getDefaultFeeData, setValue]);

  const {
    isLoading: isUpdateRequestLoading,
    showsQuotationUserMailModal,
    setShowsQuotationUserMailModal,
    handleQuotationUserUpdate,
    handleQuotationUpdate,
    ResponseHandler: ResponseHandlerOfUpdateRequest,
  } = useUpdateRequest({
    shipmentDetail: bidDetail,
    linerList,
    exchangeRateList,
    formData: watch(),
  });

  const forwarderFareUpdate = () => {
    if (!applyFares) {
      handleSnackbarOpen("실시간 견적이 없습니다.", "warning");
      return;
    }

    setValue("localFeeData", applyFares.localFee);
    setValue("freightFeeData", applyFares.freightFee);
    setValue("domesticFeeData", applyFares.domesticFee);
    setValue("inlandFeeData", applyFares.inlandFee);
    setValue("otherFeeData", applyFares.otherFee);
    setValue("taxFeeData", applyFares.taxFee);
    setValue("liner", applyFares?.liner || "");
    setValue("leadtime", applyFares?.leadtime || 0);
    setValue("freightPaymentType", applyFares?.freightPaymentType || "cc");
    setValue("isTransit", applyFares?.isTransit ?? false);
    setValue("expiredAt", applyFares?.expiredAt || new Date());

    setIsLoadedApplyFare(true);
  };

  const setComments = useCallback(() => {
    if (!bidDetail) return [];

    const {
      isImport,
      serviceType,
      startPort,
      startViaPort,
      startType,
      endPort,
      endViaPort,
      endType,
    } = bidDetail ?? {};

    const originInfo = {
      origin: {
        name: startType === "inland" ? startViaPort?.name : startPort.name,
      },
    };

    const destinationInfo = endType === "inland" ? endViaPort : endPort;

    const comment = getCautionI18nKeyListByTransportCargoType({
      shipmentType: isImport ? "importation" : "exportation",
      transportCargoType:
        serviceType === "consolidation" ? "Consol" : bidDetail.freightType,
      hasShipmentInfo: isImport ? endType === "inland" : startType === "inland",
      originInfo,
      destinationInfo,
      selectedSchedule: false,
      isOceanTicketRoute: serviceType === "oceanTicket",
    }).map((i18nKey) => t(i18nKey));

    const commentInInputValue = comment
      .map((text, index) => `${index + 1}. ${text}`)
      .join("\n");

    setValue("comment", commentInInputValue);
  }, [bidDetail, setValue, t]);

  const isLoading =
    !bidDetail ||
    !exchangeRateList ||
    !applyItems ||
    !linerList ||
    isShipmentDetailFetching;

  return (
    <Layout title="견적서 생성">
      {isLoading ? (
        <Loading active={true} />
      ) : (
        <>
          <ItemInfoForRead
            bidDetail={bidDetail}
            exchangeRateList={exchangeRateList}
          />

          {isLoadedApplyFare ? (
            <FormProvider {...methods}>
              <QuotationForm
                bidDetail={bidDetail}
                freightType={bidDetail.freightType}
                exchangeRateList={exchangeRateList}
                applyItems={applyItems}
                linerList={linerList}
                onSubmit={handleQuotationUpdate}
              >
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isUpdateRequestLoading}
                >
                  {
                    /** 페이지 진입 시 확정견적이 이미 있다면 확정 견적 재수정 */
                    bidDetail?.quotationsUser.length === 0
                      ? "견적 초안 생성"
                      : "확정 견적 재수정"
                  }
                </Button>
              </QuotationForm>
            </FormProvider>
          ) : (
            <Grid
              item
              container
              direction="column"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <ErrorOutlineIcon color="error" />
              </Grid>

              <Grid item>
                <Typography variant="subtitle1" color="error">
                  {applyFares && isNotEmptyObjectOrArray(applyFares)
                    ? "해당 구간에 등록한 임시 견적이 있습니다. 해당 견적을 불러오시겠습니까?"
                    : bidDetail?.isImport
                    ? "해당 구간에 등록된 임시 견적이 없습니다. 쉽다 기본항목을 불러오시겠습니까?"
                    : "해당 구간에 등록한 임시 견적이 없습니다."}
                </Typography>
              </Grid>

              {bidDetail?.isImport && (
                <Grid item>
                  <Typography variant="subtitle2">
                    {applyFares
                      ? "아니오를 누르시면 쉽다 기본항목을 제공합니다."
                      : "아니오를 누르시면 처음부터 작성 가능합니다."}
                  </Typography>
                </Grid>
              )}

              <Grid item container justifyContent="center" spacing={2}>
                {bidDetail?.isImport && (
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="warning"
                      onClick={() => {
                        setComments();

                        if (applyFares && isNotEmptyObjectOrArray(applyFares)) {
                          return defaultFeeDataUpdate();
                        }

                        setIsLoadedApplyFare(true);
                      }}
                    >
                      아니오
                    </Button>
                  </Grid>
                )}

                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setComments();

                      // 임시견적 항목이 있는 경우 임시견적 항목을 채워준다.
                      if (applyFares && isNotEmptyObjectOrArray(applyFares)) {
                        forwarderFareUpdate();
                        return;
                      }

                      // 수출일때는 하드코딩된 기본 견적 항목을 제공하지 않음
                      if (!bidDetail?.isImport) {
                        setIsLoadedApplyFare(true);
                        return;
                      }

                      defaultFeeDataUpdate();
                      return;
                    }}
                  >
                    {bidDetail?.isImport ? "예" : "확인"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}

      {showsQuotationUserMailModal && (
        <QuotationUserMailModal
          showsQuotationUserMailModal={showsQuotationUserMailModal}
          onQuotationUserMailModalClose={() =>
            setShowsQuotationUserMailModal(false)
          }
          onQuotationUserUpdate={handleQuotationUserUpdate}
        />
      )}

      {ResponseHandlerOfUpdateRequest}
    </Layout>
  );
};

export default BidQuotationNew;
