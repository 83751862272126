import { useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { Grid, Typography } from "@mui/material";

import ADMIN_BID_CREATE_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_BID_CREATE_QUERY";
import { BidCreateStorageData } from "@sellernote/shared/src/types/forwarding/adminBidCreate";

import ContainersInfo from "./ContainersInfo";
import ProductsInfo from "./ProductsInfo";

function CargoInfo({
  POId,
  sessionStorageShipmentCreateInfo,
}: {
  POId?: number;
  sessionStorageShipmentCreateInfo: BidCreateStorageData;
}) {
  const { watch } = useFormContext();

  const { data: productsData } =
    ADMIN_BID_CREATE_QUERY.useGetPurchaseOrderProducts({
      /**
       * 발주에서 등록한 품목을 전부 노출
       * 값이 true일 경우 발주에서 등록한 품목 중 동일 발주의 다른 운송의뢰에서 매핑되지 않은 품목만 노출
       *
       * 논의 기록: https://sellernotehq.slack.com/archives/C02R1BN5YA0/p1738909188848629?thread_ts=1738889122.279299&cid=C02R1BN5YA0
       */
      onlyUnmappedBid: false,
      POId,
      enabled: !!POId && !!sessionStorageShipmentCreateInfo.isOpenApiAuth,
    });

  const freightType = watch("freightType");

  const ItemInfoByFreightType = useMemo(() => {
    if (freightType === "FCL") {
      return <ContainersInfo productsData={productsData} />;
    }
    if (freightType === "LCL" || freightType === "AIR") {
      return <ProductsInfo productsData={productsData} />;
    }
  }, [freightType, productsData]);

  return (
    <Grid item container alignItems="flex-start">
      <Grid item xs={1}>
        <Typography variant="subtitle1" component="div">
          물품 정보
        </Typography>
      </Grid>

      {ItemInfoByFreightType}
    </Grid>
  );
}

export default CargoInfo;
