import { RadioGroupWithReactHookFormOption } from "@sellernote/shared/src/admin-ui/components/RadioGroupWithReactHookForm";
import {
  BooleanStringV2,
  InlnadTransportType,
} from "@sellernote/shared/src/types/common/common";
import { BidTransportType } from "@sellernote/shared/src/types/forwarding/bid";

const inclusionConditionGroupOptionList: RadioGroupWithReactHookFormOption<BooleanStringV2>[] =
  [
    {
      label: "포함",
      value: "TRUE",
    },
    {
      label: "미포함",
      value: "FALSE",
    },
  ];

const containerAccessableRadioGroupOptionList: RadioGroupWithReactHookFormOption<BooleanStringV2>[] =
  [
    {
      label: "가능",
      value: "TRUE",
    },
    {
      label: "불가능",
      value: "FALSE",
    },
  ];

const transPortTypeRadioGroupOptionList: RadioGroupWithReactHookFormOption<BidTransportType>[] =
  [
    {
      label: "내륙",
      value: "inland",
    },
    {
      label: "해상",
      value: "sea",
    },
    {
      label: "항공",
      value: "air",
    },
  ];

const containerStuffingRadioGroupOptionList: RadioGroupWithReactHookFormOption<BooleanStringV2>[] =
  [
    {
      label: "예",
      value: "TRUE",
    },
    {
      label: "아니오",
      value: "FALSE",
    },
  ];

const exportCheckpointList = [
  "containerStuffing",
  "inlandTransportType",
  "exportCustoms",
  "useVGM",
];

const importCheckpointList = [
  "containerAccessable",
  "containDomesticFee",
  "containOceanSurcharge",
  "containLss",
];

const inlandTypeRadioGroupOptions: RadioGroupWithReactHookFormOption<InlnadTransportType>[] =
  [
    {
      label: "독차",
      value: "sole",
    },
    {
      label: "합차",
      value: "consol",
    },
    {
      label: "택배",
      value: "parcel",
    },
  ];
export {
  inclusionConditionGroupOptionList,
  containerAccessableRadioGroupOptionList,
  transPortTypeRadioGroupOptionList,
  containerStuffingRadioGroupOptionList,
  exportCheckpointList,
  importCheckpointList,
  inlandTypeRadioGroupOptions,
};
