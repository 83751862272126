import { useCallback, useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";

import QuotationDownload from "@sellernote/shared/src/admin-ui/pageContainers/bid/detail/QuotationDownload";
import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import useSessionStorage from "@sellernote/shared/src/hooks/common/useSessionStorage";
import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import ADMIN_COUPON_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_COUPON_QUERY";
import { AdminBidDetail } from "@sellernote/shared/src/types/forwarding/adminBid";
import { formatDate } from "@sellernote/shared/src/utils/common/date";
import { toThousandUnitFormat } from "@sellernote/shared/src/utils/common/number";
import { changeQuotationsStatusToKr } from "@sellernote/shared/src/utils/forwarding/bid";
import { COLOR } from "@sellernote/shared/src/sds-v2/styles/colors";

import QuotationConfirm from "./QuotationConfirm";
import QuotationCouponModal from "./QuotationCouponModal";

function QuotationDesc({ bidDetail }: { bidDetail: AdminBidDetail }) {
  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const history = useHistory();

  const [_, setSessionInvoiceId] = useSessionStorage<string | null>(
    "invoiceId"
  );

  const [showsQuotationCouponModal, setShowsQuotationCouponModal] =
    useState(false);

  const { coupon, quotationForwarders, quotationsUser, projectStatus } =
    bidDetail;

  const draftQuotation = quotationForwarders[0];
  const fixedQuotation = quotationsUser[0];

  const isMoveToTradingStatementButtonDisabled = (() => {
    const { status, projectStatus, management } = bidDetail;

    const isStatusWaitingOrWaitingForExporterInfo =
      status === "waiting" || status === "waitingForExporterInfo";

    const isProjectStatusBeforeMoving =
      projectStatus === "beforeContactPartner" ||
      projectStatus === "contactingPartner" ||
      projectStatus === "scheduling";

    const isProjectStatusMovingWithoutDetailStatus =
      projectStatus === "moving" && management.detailStatus === null;

    return (
      isStatusWaitingOrWaitingForExporterInfo ||
      isProjectStatusBeforeMoving ||
      isProjectStatusMovingWithoutDetailStatus
    );
  })();

  const showsCouponSendButton =
    projectStatus === "beforeContactPartner" ||
    projectStatus === "contactingPartner" ||
    projectStatus === "scheduling" ||
    projectStatus === "canceled" ||
    projectStatus === "moving" ||
    projectStatus === "portEntryAndPrepareCustoms";

  const handleMoveToTradingStatementPageClick = useCallback(() => {
    const invoiceId = bidDetail?.bidInvoice
      ? bidDetail.bidInvoice.id.toString()
      : null;

    // 거래명세서에서 페이지에서 사용할 명세서 고유 아이디 저장
    setSessionInvoiceId(invoiceId);

    history.push(
      `/tradingStatement/invoice/${bidDetail.id}/${
        bidDetail.isImport ? "import" : "export"
      }`
    );
  }, [
    bidDetail.bidInvoice,
    bidDetail.id,
    bidDetail.isImport,
    history,
    setSessionInvoiceId,
  ]);

  const {
    mutateAsync: changeBidDetail,
    ResponseHandler: ResponseHandlerOfChangeBidDetail,
  } = ADMIN_BID_QUERY.useChangeBidDetail(bidDetail.id);

  const {
    mutate: cancelCoupon,
    ResponseHandler: ResponseHandlerOfCancelCoupon,
  } = ADMIN_COUPON_QUERY.useCancelCoupon();

  const handleCouponDelete = (couponCode: string) => {
    cancelCoupon(
      { couponCode, pathParams: { shipmentId: bidDetail.id } },
      {
        onSuccess: () => {
          handleSnackbarOpen("쿠폰을 취소했습니다.");
          queryClient.invalidateQueries(
            ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
              bidId: bidDetail.id,
            })
          );
        },

        onError: () => {
          handleSnackbarOpen("쿠폰취소에 실패했습니다.", "error");
        },
      }
    );
  };

  return !draftQuotation && !fixedQuotation ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        rowGap: "8px",
        py: "24px",
        backgroundColor: COLOR.grayScale_50,
      }}
    >
      <Box sx={{ textAlign: "center" }}>
        <Typography
          className="sads"
          color={COLOR.bk_60}
          sx={{ display: "inline", whiteSpace: "nowrap" }}
        >
          본 의뢰건은 수동견적입니다. 견적서 초안을 생성해주세요.&nbsp;
        </Typography>

        <Typography
          className="sads"
          color="green"
          sx={{ display: "inline", whiteSpace: "nowrap" }}
        >
          (견적서 초안 생성 시, 견적 산출 중 상태로 자동 변경됩니다.)
        </Typography>
      </Box>

      <Button
        variant="contained"
        endIcon={<ChevronRightIcon />}
        onClick={async () => {
          if (bidDetail.status === "committed") {
            await changeBidDetail(
              { status: "estimating" },
              {
                onSuccess() {
                  handleSnackbarOpen("견적 산출 중 상태로 변경되었습니다.");
                },
              }
            );
          }

          history.push(`/bid/detail/${bidDetail.id}/quotation/new`);
        }}
      >
        견적서 초안 생성하기
      </Button>
    </Box>
  ) : (
    <Box sx={{ display: "flex", columnGap: "32px" }}>
      {draftQuotation && (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              rowGap: "8px",
              minWidth: "250px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="subtitle1" className="sads">
                견적서 초안
              </Typography>

              <Box sx={{ display: "flex", columnGap: "8px" }}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    history.push(`/bid/detail/${bidDetail.id}/quotation/draft`);
                  }}
                >
                  {!fixedQuotation ? "수정" : "보기"}
                </Button>

                {!fixedQuotation && (
                  <QuotationConfirm
                    bidId={bidDetail.id}
                    quotationId={draftQuotation.id}
                    totalPrice={draftQuotation.totalPrice}
                    isImport={bidDetail.isImport}
                    triggerSize="small"
                  />
                )}
              </Box>
            </Box>

            <Box sx={{ display: "flex", columnGap: "4px" }}>
              <Typography className="sads" color={COLOR.bk_60}>
                제출가:
              </Typography>

              <Typography className="sads">
                {toThousandUnitFormat(draftQuotation.totalPrice)}원
              </Typography>
            </Box>

            <Box sx={{ display: "flex", columnGap: "4px" }}>
              <Typography className="sads" color={COLOR.bk_60}>
                제출일자:
              </Typography>

              <Typography className="sads">
                {formatDate({
                  date: draftQuotation.createdAt,
                  type: "YY_MM_DD",
                })}
              </Typography>
            </Box>

            <Box sx={{ display: "flex", columnGap: "4px" }}>
              <Typography className="sads" color={COLOR.bk_60}>
                유효기간:
              </Typography>

              <Typography className="sads">
                {formatDate({
                  date: draftQuotation.expiredAt,
                  type: "YY_MM_DD",
                })}
              </Typography>
            </Box>
          </Box>

          <Divider orientation="vertical" />
        </>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "8px",
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            columnGap: "8px",
          }}
        >
          <Typography
            variant="subtitle1"
            className="sads"
            sx={{ flexShrink: 0 }}
          >
            확정 견적
          </Typography>

          {fixedQuotation && (
            <Box sx={{ display: "flex", columnGap: "8px" }}>
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  history.push(`/bid/detail/${bidDetail.id}/quotation/fixed`);
                }}
              >
                수정
              </Button>

              <QuotationDownload bidId={bidDetail.id} />

              <Button
                variant="contained"
                size="small"
                disabled={isMoveToTradingStatementButtonDisabled}
                onClick={handleMoveToTradingStatementPageClick}
                color="info"
              >
                거래명세서 생성
              </Button>
            </Box>
          )}
        </Box>

        {!fixedQuotation ? (
          <Box
            sx={{
              p: "24px",
              backgroundColor: COLOR.grayScale_50,
              textAlign: "center",
            }}
          >
            <Typography
              className="sads"
              color={COLOR.bk_60}
              sx={{ display: "inline", whiteSpace: "nowrap" }}
            >
              확정된 견적이 없습니다. 초안 견적을 확정해주세요.
            </Typography>
          </Box>
        ) : (
          <>
            <Box
              sx={{ display: "flex", flexDirection: "column", rowGap: "8px" }}
            >
              {fixedQuotation && (
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "8px",
                  }}
                >
                  <Box sx={{ display: "flex", columnGap: "4px" }}>
                    <Typography className="sads" color={COLOR.bk_60}>
                      제출가:
                    </Typography>

                    <Typography className="sads">
                      {toThousandUnitFormat(fixedQuotation.totalPrice)}원
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", columnGap: "4px" }}>
                    <Typography className="sads" color={COLOR.bk_60}>
                      제출일자:
                    </Typography>

                    <Typography className="sads">
                      {formatDate({
                        date: fixedQuotation.createdAt,
                        type: "YY_MM_DD",
                      })}
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", columnGap: "4px" }}>
                    <Typography className="sads" color={COLOR.bk_60}>
                      상태:
                    </Typography>

                    <Typography className="sads">
                      {changeQuotationsStatusToKr(fixedQuotation.status)}
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", columnGap: "4px" }}>
                    <Typography className="sads" color={COLOR.bk_60}>
                      유효기간:
                    </Typography>

                    <Typography className="sads">
                      {formatDate({
                        date: fixedQuotation.expiredAt,
                        type: "YY_MM_DD",
                      })}
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", columnGap: "4px" }}>
                    <Typography className="sads" color={COLOR.bk_60}>
                      자동견적 여부:
                    </Typography>

                    <Typography className="sads">
                      {bidDetail.isFixed ? "O" : "X"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      columnGap: "4px",
                      alignItems: "center",
                    }}
                  >
                    <Typography className="sads" color={COLOR.bk_60}>
                      쿠폰:
                    </Typography>

                    {showsCouponSendButton ? (
                      coupon ? (
                        <Button
                          variant="text"
                          size="small"
                          color="error"
                          onClick={() => handleCouponDelete(coupon.code)}
                        >
                          쿠폰 삭제하기
                        </Button>
                      ) : (
                        <Button
                          variant="text"
                          size="small"
                          onClick={() => setShowsQuotationCouponModal(true)}
                        >
                          쿠폰 등록하기
                        </Button>
                      )
                    ) : (
                      "-"
                    )}

                    <QuotationCouponModal
                      freightType={bidDetail.freightType}
                      setShowsQuotationCouponModal={
                        setShowsQuotationCouponModal
                      }
                      showsQuotationCouponModal={showsQuotationCouponModal}
                      teamId={bidDetail.teamId}
                      userId={bidDetail.userId}
                      shipmentId={bidDetail.id}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </>
        )}
      </Box>

      {ResponseHandlerOfChangeBidDetail}
      {ResponseHandlerOfCancelCoupon}
    </Box>
  );
}

export default QuotationDesc;
