import { Divider } from "@mui/material";

import Layout from "@sellernote/shared/src/admin-ui/containers/Layout";

import Content from "./Content";
import Header from "./Header";

function AccountManagement() {
  return (
    <Layout>
      <Header />

      <Divider sx={{ mt: 1 }} />

      <Content />
    </Layout>
  );
}

export default AccountManagement;
