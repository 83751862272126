import { useFormContext } from "react-hook-form";
import { Grid, Typography } from "@mui/material";

import CheckboxWithReactHookForm from "@sellernote/shared/src/admin-ui/components/CheckboxWithReactHookForm";
import { BidCreateFormData } from "@sellernote/shared/src/types/forwarding/adminBidCreate";

function BusinessAgency() {
  const { control, watch } = useFormContext<BidCreateFormData>();

  const bidType = watch("bidType");
  const incoterms = watch("incoterms");
  const freightType = watch("freightType");

  const isExport = bidType === "export";

  return (
    <Grid item container xs={12} alignItems="center">
      <Grid item xs={1}>
        <Typography variant="subtitle1" component="span">
          업무대행
        </Typography>
      </Grid>

      {!isExport && (
        <Grid item xs={2}>
          <CheckboxWithReactHookForm
            name={"containCustoms"}
            control={control}
            label="통관"
            defaultValue={false}
          />
        </Grid>
      )}

      {isExport && (
        <Grid item xs={2}>
          <CheckboxWithReactHookForm
            name={"exportCustoms"}
            control={control}
            label="수출통관"
            defaultValue={false}
          />
        </Grid>
      )}

      <Grid item xs={2}>
        <CheckboxWithReactHookForm
          name={"hopeCargoInsurance"}
          control={control}
          label="적하보험"
          defaultValue={false}
        />
      </Grid>

      {!isExport && (
        <Grid item xs={2}>
          <CheckboxWithReactHookForm
            name={"needFTACertificateAgency"}
            control={control}
            label="FTA C/O"
            defaultValue={false}
          />
        </Grid>
      )}

      {isExport && (
        <Grid item xs={2}>
          <CheckboxWithReactHookForm
            name={"useVGM"}
            control={control}
            label="VGM"
            disabled={freightType !== "FCL"}
            defaultValue={false}
          />
        </Grid>
      )}

      {isExport && (
        <Grid item xs={2}>
          <CheckboxWithReactHookForm
            name={"importCustoms"}
            control={control}
            label="수입통관"
            defaultValue={false}
            disabled={incoterms !== "DDP" && incoterms !== "DAP"}
          />
        </Grid>
      )}
    </Grid>
  );
}

export default BusinessAgency;
