import {
  AccountDetailFile,
  AccountListFilter,
  AccountPartnerInfo,
  AccountTeamInfo,
} from "@sellernote/shared/src/types/forwarding/adminAccount";

import { atomWithReset, selectAtom } from "jotai/utils";

/** 거래처 관리 > 테이블 필터 */
const ACCOUNT_TABLE_FILTER = atomWithReset<AccountListFilter>({
  /** 페이지 (0부터 시작) */
  page: 0,
  /** 페이지당 리스트 개수 */
  perPage: 10,
  /** 거래처 아이디 */
  id: undefined,
  /** 업무담당자 이메일 */
  managerEmail: undefined,
  /** 세금계산서 수신용 이메일 */
  taxEmail: undefined,
  /** 팀 이름 */
  teamNames: undefined,
  /** 파트너 이름 */
  partnerNames: undefined,
  /** 구분 */
  businessArea: undefined,
  /** 거래처 유형 */
  category: undefined,
  /** 지급 조건 */
  paymentCondition: undefined,

  /** 검색 대상 */
  searchOption: {},
});

/** 거래처 관리 > 테이블 Row 클릭시 상세보기를 위한 Id */
const ACCOUNT_DETAIL_ID = atomWithReset<number | undefined>(undefined);

/** 거래처 생성 > 선택한 팀 */
const SELECTED_TEAM_LIST = atomWithReset<AccountTeamInfo[]>([]);
/** 거래처 생성 > 선택한 파트너 */
const SELECTED_PARTNER_LIST = atomWithReset<AccountPartnerInfo[]>([]);

/** 거래처 생성 > 선택한 팀의 id 배열 */
const SELECTED_TEAM_ID_LIST = selectAtom(SELECTED_TEAM_LIST, (teamList) =>
  teamList.map(({ id }) => id)
);
/** 거래처 생성 > 선택한 파트너의 id 배열 */
const SELECTED_PARTNER_ID_LIST = selectAtom(
  SELECTED_PARTNER_LIST,
  (partnerList) => partnerList.map(({ id }) => id)
);

/** 거래처 상세 > 매핑된 팀 */
const MAPPED_TEAM_LIST = atomWithReset<AccountTeamInfo[]>([]);
/** 거래처 상세 > 매핑된 파트너 */
const MAPPED_PARTNER_LIST = atomWithReset<AccountPartnerInfo[]>([]);

/** 거래처 상세 > 매핑된 팀의 id 배열 */
const MAPPED_TEAM_ID_LIST = selectAtom(MAPPED_TEAM_LIST, (teamList) =>
  teamList.map(({ id }) => id)
);

/** 거래처 상세 > 매핑된 파트너의 id 배열 */
const MAPPED_PARTNER_ID_LIST = selectAtom(MAPPED_PARTNER_LIST, (partnerList) =>
  partnerList.map(({ id }) => id)
);

/** 거래처 상세 > 파일 미리보기 */
const FILE_PREVIEW = atomWithReset<AccountDetailFile | undefined>(undefined);

export default {
  ACCOUNT_TABLE_FILTER,
  ACCOUNT_DETAIL_ID,

  SELECTED_TEAM_LIST,
  SELECTED_PARTNER_LIST,

  SELECTED_TEAM_ID_LIST,
  SELECTED_PARTNER_ID_LIST,

  MAPPED_TEAM_ID_LIST,
  MAPPED_PARTNER_ID_LIST,

  MAPPED_TEAM_LIST,
  MAPPED_PARTNER_LIST,

  FILE_PREVIEW,
};
