import { useMemo } from "react";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import FlightRoundedIcon from "@mui/icons-material/FlightRounded";
import { Grid, Typography } from "@mui/material";

import { getCountryCodeKR } from "@sellernote/shared/src/utils/common/country";

import { PortStepData } from ".";

export default function usePortStepData(portStepData: PortStepData) {
  const getStartAndEndAddressColor = (type: "startAddress" | "endAddress") => {
    const isConsolidation = portStepData.serviceType === "consolidation";

    const hasWarehouseName =
      type === "startAddress" && portStepData.wareHouse?.name;

    const hasAddress = !!portStepData[type];

    if (isConsolidation) {
      // 특송의뢰 출발지는 창고명이 있는지를 확인한다 도착지는 주소여부로 확인한다.
      if (hasWarehouseName || hasAddress) {
        return "primary";
      }
    }

    return hasAddress ? "primary" : "disabled";
  };

  const targetStartPort = (() => {
    if (portStepData.serviceType === "consolidation") {
      // 콘솔은 startType관계없이 startPort를 사용한다
      return portStepData.startPort;
    }

    return portStepData.startType === "inland"
      ? portStepData.startViaPort
      : portStepData.startPort;
  })();

  const targetEndPort = (() => {
    return portStepData.endType === "inland"
      ? portStepData.endViaPort
      : portStepData.endPort;
  })();

  const endAddressName = useMemo(() => {
    if (portStepData.endAddress) {
      return `${getCountryCodeKR(portStepData.endCountry)}내륙`;
    }
    return "-";
  }, [portStepData.endAddress, portStepData.endCountry]);

  const startAddressName = useMemo(() => {
    if (portStepData.serviceType === "consolidation") {
      return `${portStepData.wareHouse?.name}창고`;
    }
    if (portStepData.startAddress) {
      return `${getCountryCodeKR(portStepData.startCountry)}내륙`;
    }
    return "-";
  }, [
    portStepData.serviceType,
    portStepData.startAddress,
    portStepData.startCountry,
    portStepData.wareHouse?.name,
  ]);

  const startAddressDetailName = useMemo(() => {
    if (portStepData.serviceType === "consolidation") {
      return portStepData.wareHouse?.addressCN;
    }

    if (portStepData.startAddress) {
      // 수출일 때는 startAddressDetail이 있다.
      if (!portStepData.isImport) {
        return `${portStepData.startAddress}${portStepData.startAddressDetail}`;
      }

      return portStepData.startAddress;
    }
    return "-";
  }, [
    portStepData.isImport,
    portStepData.serviceType,
    portStepData.startAddress,
    portStepData.startAddressDetail,
    portStepData.wareHouse?.addressCN,
  ]);

  const startPortIcon = (() => {
    if (!targetStartPort) {
      return null;
    }

    return targetStartPort.type === "air" ? (
      <FlightRoundedIcon fontSize="large" color="primary" />
    ) : (
      <DirectionsBoatIcon fontSize="large" color="primary" />
    );
  })();

  const startPortName = useMemo(() => {
    return (
      <Grid item container direction="column" alignItems={"center"}>
        <Grid item>
          <Typography variant="body2" component="div" color="primary">
            {getCountryCodeKR(targetStartPort?.country ?? "")}
            {targetStartPort?.type === "air" ? "공항" : "항"}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" component="div" color="primary">
            ({targetStartPort?.name || targetStartPort?.nameEN})
          </Typography>
        </Grid>
      </Grid>
    );
  }, [
    targetStartPort?.country,
    targetStartPort?.name,
    targetStartPort?.nameEN,
    targetStartPort?.type,
  ]);

  const endPortIcon = (() => {
    if (!targetEndPort) {
      return null;
    }

    return targetEndPort.type === "air" ? (
      <FlightRoundedIcon fontSize="large" color="primary" />
    ) : (
      <DirectionsBoatIcon fontSize="large" color="primary" />
    );
  })();

  const endPortName = useMemo(() => {
    return (
      <Grid item container direction="column" alignItems={"center"}>
        <Grid item>
          <Typography variant="body2" component="div" color="primary">
            {getCountryCodeKR(targetEndPort?.country ?? "")}
            {targetEndPort?.type === "air" ? "공항" : "항"}
          </Typography>
        </Grid>

        <Grid item>
          <Typography variant="body2" component="div" color="primary">
            ({targetEndPort?.name || targetEndPort?.nameEN})
          </Typography>
        </Grid>
      </Grid>
    );
  }, [
    targetEndPort?.country,
    targetEndPort?.name,
    targetEndPort?.nameEN,
    targetEndPort?.type,
  ]);

  const endAddressDetailName = useMemo(() => {
    if (portStepData.serviceType === "consolidation") {
      if (portStepData.endAddressDetail) {
        return `${portStepData.endAddress}${portStepData.endAddressDetail}`;
      }

      if (portStepData.zone?.name)
        return `${portStepData.zone?.name}${portStepData.endAddress}`;

      return "-";
    }

    if (portStepData.endAddress) {
      if (!portStepData.isImport) {
        return `${portStepData.endAddress}`;
      }
      return `${portStepData.endAddress}${portStepData.endAddressDetail}`;
    }
    return "-";
  }, [
    portStepData.endAddress,
    portStepData.endAddressDetail,
    portStepData.isImport,
    portStepData.serviceType,
    portStepData.zone?.name,
  ]);

  return {
    endAddressName,
    startAddressName,
    endAddressDetailName,
    startAddressDetailName,
    startPortIcon,
    endPortIcon,
    startPortName,
    endPortName,
    getStartAndEndAddressColor,
  };
}
