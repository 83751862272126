import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import DifferenceIcon from "@mui/icons-material/Difference";
import { Box, Button, Divider } from "@mui/material";

import { COPY_SHIPMENT_FOR_IMPORT_RES } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminBid";
import useSnackbar from "@sellernote/shared/src/hooks/admin/useSnackbar";
import ADMIN_BID_QUERY from "@sellernote/shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import { ShipmentType } from "@sellernote/shared/src/types/common/common";
import { AdminBidDetail } from "@sellernote/shared/src/types/forwarding/adminBid";
import {
  BidCopyStatus,
  BidCopyTargetRecordForExportation,
  BidCopyTargetRecordForImportation,
} from "@sellernote/shared/src/types/forwarding/adminBid";

import ContactingPartnerModal from "./ContactingPartnerModal";
import RequestQuoteToPartnerModalContainer from "./RequestQuoteToPartnerModalContainer";

/**
 * <참고>
 * 의뢰 복사 요청과 연결된 플로우가 2개가 있다.
 * - 견적파트너에게 컨택
 *   - 복사 성공후 별도의 API를 호출하는 방식.
 * - 부킹파트너에게 컨택
 *   - 복사 요청 전에, 추가 form을 받아 (ContactingPartnerModal을 open) 의뢰 복사 요청의 payload에 추가해서 보내줌.
 */
export default function SubmitSection({
  bidDetail,
  selectedCopyStatus,
  copyTargetRecord,
  setShowsBidCopyModal,
}: {
  bidDetail: AdminBidDetail;
  selectedCopyStatus: BidCopyStatus;
  copyTargetRecord:
    | BidCopyTargetRecordForImportation
    | BidCopyTargetRecordForExportation;
  setShowsBidCopyModal: (v: boolean) => void;
}) {
  const { id }: { id: string } = useParams();

  const { handleSnackbarOpen } = useSnackbar();

  const history = useHistory();

  const { id: bidId, isImport, team } = bidDetail;

  const [isCopying, setIsCopying] = useState(false);

  const [copiedBidId, setCopiedBidId] = useState(0);

  const [showsContactingPartnerModal, setShowsContactingPartnerModal] =
    useState(false);

  const [showsRequestQuoteToPartnerModal, setShowsRequestQuoteToPartnerModal] =
    useState(false);

  const { data: partnerQuote } = ADMIN_BID_QUERY.useGetAdminBidPartnerQuote({
    bidId,
  });

  const {
    mutate: copyShipmentForImport,
    ResponseHandler: ResponseHandlerOfCopyShipmentForImport,
  } = ADMIN_BID_QUERY.useCopyShipmentForImport();

  const {
    mutate: copyShipmentForExport,
    ResponseHandler: ResponseHandlerOfCopyShipmentForExport,
  } = ADMIN_BID_QUERY.useCopyShipmentForExport();

  const shipmentType: ShipmentType = bidDetail.isImport
    ? "importation"
    : "exportation";

  const copyShipment =
    shipmentType === "importation"
      ? copyShipmentForImport
      : copyShipmentForExport;

  const handleAdminBidCopy = () => {
    setIsCopying(true);

    const handleSuccessResponse = (res: {
      data: COPY_SHIPMENT_FOR_IMPORT_RES;
    }) => {
      setIsCopying(false);

      if (copyTargetRecord.contactEstimatePartner) {
        // 복사 할 의뢰에서 컨택한 견적파트너가 없는 경우 알려준다.
        if (partnerQuote && partnerQuote?.list.length < 1) {
          setCopiedBidId(res.data.id);
          handleSnackbarOpen(
            "의뢰복사는 했지만 컨택할 견적파트너가 없습니다.",
            "error"
          );
          history.push(`/bid/detail/${res.data.id}`);
          setShowsRequestQuoteToPartnerModal(false);
          setShowsBidCopyModal(false);
          return;
        }

        setCopiedBidId(res.data.id);
        setShowsRequestQuoteToPartnerModal(true);
        return;
      }

      history.push(`/bid/detail/${res.data.id}`);
      setShowsBidCopyModal(false);
    };

    copyShipment(
      {
        pathParams: { shipmentId: Number(id) },
        targetStatus: selectedCopyStatus,
        copyTarget: copyTargetRecord,
      },
      {
        onSuccess: handleSuccessResponse,
        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
          setIsCopying(false);
        },
      }
    );
  };

  return (
    <>
      <Divider sx={{ mb: 2, mt: 2 }}>
        {team.company}의 의뢰번호 {id}를 복사해서 신규 의뢰를 생성합니다.
      </Divider>

      <Box>
        <Button
          startIcon={<DifferenceIcon />}
          variant="contained"
          onClick={() => {
            if (
              copyTargetRecord.contactEstimatePartner &&
              !copyTargetRecord.copyEstimatePartner
            ) {
              // 복사 대상에 '견적파트너 컨택'을 선택했는데, '견적파트너'는 선택 안 한 경우
              handleSnackbarOpen(
                "'견적파트너 컨택'을 위해서는 '견적파트너'도 복사대상으로 선택해야합니다.",
                "error"
              );
              return;
            }

            if (
              copyTargetRecord.contactBookingPartner &&
              !copyTargetRecord.copyBookingPartner
            ) {
              // 복사 대상에 '부킹파트너 컨택'을 선택했는데, '부킹파트너'는 선택 안 한 경우
              handleSnackbarOpen(
                "'부킹파트너 컨택'을 위해서는 '지급유형, 부킹파트너'도 복사대상으로 선택해야합니다.",
                "error"
              );
              return;
            }

            if (
              copyTargetRecord.contactBookingPartner &&
              !copyTargetRecord.copyExporterInfo
            ) {
              // 복사 대상에 '부킹파트너 컨택'을 선택했는데, '수입자/수출자'는 선택 안 한 경우
              handleSnackbarOpen(
                `'부킹파트너 컨택'을 위해서는 '${
                  bidDetail.isImport ? "수출자" : "수입자"
                } 정보'도 복사대상으로 선택해야합니다.`,
                "error"
              );
              return;
            }

            if (shipmentType === "importation") {
              if (
                selectedCopyStatus === "contactingPartner" &&
                copyTargetRecord.contactBookingPartner
              ) {
                setShowsContactingPartnerModal(true);
                return;
              }
            }

            if (shipmentType === "exportation") {
              if (
                selectedCopyStatus === "waitingFreight" &&
                copyTargetRecord.contactBookingPartner
              ) {
                setShowsContactingPartnerModal(true);
                return;
              }
            }

            handleAdminBidCopy();
          }}
          disabled={isCopying}
          fullWidth
          size="small"
        >
          의뢰 복사
        </Button>
      </Box>

      <RequestQuoteToPartnerModalContainer
        copiedBidId={copiedBidId}
        showsRequestQuoteToPartnerModal={showsRequestQuoteToPartnerModal}
        setShowsRequestQuoteToPartnerModal={setShowsRequestQuoteToPartnerModal}
        setShowsBidCopyModal={setShowsBidCopyModal}
        partnerQuote={partnerQuote}
        bidDetail={bidDetail}
      />

      {showsContactingPartnerModal && (
        <ContactingPartnerModal
          showsContactingPartnerModal={showsContactingPartnerModal}
          setShowsContactingPartnerModal={setShowsContactingPartnerModal}
          bidDetail={bidDetail}
          selectedCopyStatus={selectedCopyStatus}
          copyTargetRecord={copyTargetRecord}
          setShowsBidCopyModal={setShowsBidCopyModal}
        />
      )}

      {ResponseHandlerOfCopyShipmentForImport}
      {ResponseHandlerOfCopyShipmentForExport}
    </>
  );
}
