import { GET_PURCHASE_ORDER_PRODUCTS_RES } from "@sellernote/shared/src/api-interfaces/shipda-api/admin/adminBidCreate";

function checkCanUpdateSeparator({
  productsData,
  currentProductName,
}: {
  productsData: GET_PURCHASE_ORDER_PRODUCTS_RES | undefined;
  currentProductName: string;
}): boolean {
  const originProduct = productsData?.find((v) => {
    return v.productName === currentProductName;
  });

  const hasOriginSeparator = !!originProduct?.separator;
  // 이미 separator를 갖고 있는 경우 수정할 수 없다.
  return !hasOriginSeparator;
}

export { checkCanUpdateSeparator };
