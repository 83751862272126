import { Control } from "react-hook-form";
import { Grid } from "@mui/material";

import SADSTable, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/shared/src/admin-ui/components/SADSTable";
import TextFieldWithReactHookForm from "@sellernote/shared/src/admin-ui/components/TextFieldWithReactHookForm";
import {
  AdminBidCopyContactingPartnerForm,
  AdminBidDetail,
} from "@sellernote/shared/src/types/forwarding/adminBid";

type CellKey = "품명" | "품명(영문)";

const headCellList: TableHeadCell<CellKey>[] = [
  { cellKey: "품명", label: "품명", width: 300 },
  { cellKey: "품명(영문)", label: "품명(영문)", width: 300 },
];

export default function CargoForGeneral({
  bidDetail,
  control,
}: {
  bidDetail: AdminBidDetail;
  control: Control<AdminBidCopyContactingPartnerForm>;
}) {
  const bodyRowList: TableBodyRow<CellKey>[] = (
    bidDetail.itemGroupList || []
  ).map(({ item }, index) => {
    return {
      rowKey: item.id,
      품명: {
        value: (
          <Grid container>
            <TextFieldWithReactHookForm
              name={`generalFreightInfo.${index}.item.itemName`}
              control={control}
              fullWidth
            />
          </Grid>
        ),
      },
      "품명(영문)": {
        value: (
          <Grid container>
            <TextFieldWithReactHookForm
              name={`generalFreightInfo.${index}.item.itemNameEn`}
              control={control}
              required={true}
              fullWidth
            />
          </Grid>
        ),
      },
    };
  });

  return (
    <>
      <SADSTable headCellList={headCellList} bodyRowList={bodyRowList} />
    </>
  );
}
